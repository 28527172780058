/*
 * DownloadPageForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by etiennedaherinsta, 2022
 *
 * @file DownloadPageForm.tsx
 * @author etiennedaherinsta
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import { IFileRecord } from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecord';

interface IDownloadPageFormPropertiesInterface {
  fileRecord: IFileRecord;
  downloadFile: (file: any) => void;
  returnToFiles: () => void;
}

const DownloadPageForm = (properties: IDownloadPageFormPropertiesInterface): JSX.Element => {
  const { fileRecord, downloadFile, returnToFiles } = properties;

  const footer: JSX.Element = (
    <div className="d-flex justify-content-between">
      <Button
        label="Return to files"
        icon="pi pi-arrow-left"
        className="p-button-danger p-ml-2"
        onClick={returnToFiles}
      />{' '}
      <Button
        label="Download"
        icon="pi pi-download"
        className="p-button-success p-ml-2"
        onClick={() => downloadFile(fileRecord)}
      />
    </div>
  );

  // We should scroll this page to top once it is accessed.
  // The DashboardPage component renders this component along with the DashboardForm component during the React hydration step. This means that this component is rendered as soon as the DashboardPage is accessed.
  // Clicking on a row in the files table (which may require scrolling down the page depending on how much content the DashboardPage component loads) sets a useState hook to "true",
  // causing the DownloadPageForm component to be displayed at the same scroll position as the DashboardPage component.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="download-info card-center">
      <Card className="p-mt-5 w-100" footer={footer}>
        <div className="headerContainer">
          {fileRecord.icon && <img src={fileRecord.icon} className="fixed-img" />}

          <div className={`header justify-content-center ${fileRecord.icon ? 'left-margin' : ''}`}>
            <div className="d-flex .flex-column p-card-title">
              <span className="align-end">{fileRecord.name}</span>
            </div>
            <div className="p-card-subtitle">{fileRecord.website}</div>
          </div>
        </div>
        <p className="p-m-0" style={{ lineHeight: '1.5' }}>
          {fileRecord.description}
        </p>
      </Card>
    </div>
  );
};

export default DownloadPageForm;

/**
* AuthenticationVerificationPage.tsx (abstractuser) *

* Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Timothy Fadayini, 2021
* @file AuthenticationVerificationPage.tsx
* @author Timothy Fadayini
* @copyright 2021 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { Dispatch, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { checkIsAdmin, hasApplication, isTokenValid } from '../../Services/Auth';
import { signUserIntoApplicationAction } from '../../Store/Auth';
import { getConfigurationSettings, readApplicationDetails, readSafe } from '../../Services/Setting';
import jwtDecode from 'jwt-decode';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { IGetSystemSettingsResponse } from '@abstract/abstractwebcommon-shared/interfaces/license/api';
import {
  IUserInformationToken,
  UserAuthenticationToken
} from '@abstract/abstractwebcommon-shared/utils/UserAuthenticationToken';
import { ISetting } from '@abstract/abstractwebcommon-shared/interfaces/license/setting';
import {
  ILogoutAllApplications,
  logoutAllApplications
} from '@abstract/abstractwebcommon-client/utils/LogoutAllApplications';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLogApi } from '../../Services/Log';
import { changeUserTheme } from '@abstract/abstractwebcommon-client/utils/themeModeUtils';
import { customLocalStorageClearUtil } from '../../Utils/localStorageUtils';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { RouteName } from '../../Utils/routesNames';
import {
  SharedCommomRouteName,
  SharedMainRouteName
} from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import { changeUserLanguage } from '@abstract/abstractwebcommon-client/utils/LanguageSettingsModeUtils';
import i18n from '../../Services/I18n';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

const AuthenticationVerificationPage = (): ReactElement => {
  const dispatch: Dispatch<any> = useDispatch();

  const search: string = useLocation().search;
  const parsedString: any = queryString.parse(search);
  const token: string = parsedString['token'];
  const themeMode: string | null =
    parsedString['themeMode'] == 'null' ? ThemeMode.lightMode : parsedString['themeMode'];
  const redirectURL: string = parsedString['redirect_url'];
  const isLogout: boolean = parsedString['logout']; // this is user triggered logout, by clicking logout
  const autoLogoutReason: string = parsedString['autoLogoutReason']; // error to send with autoLogout
  const history: any = useHistory();
  const loggedOutapplicationUUID: string = parsedString['app']; /**< ApplicationUUID. */
  const isLogoutAll: boolean = parsedString['logoutAll']; /**< isLogoutAll */
  const userApplications: IApplications[] = parsedString['userApplications']
    ? JSON.parse(parsedString['userApplications'])
    : []; /**< UserApplications */
  const redirectApplicationURL: string =
    parsedString['redirect_applicationURL']; /**< Redirect application URL. */
  const languageSettingsMode: string | null =
    parsedString['languageSettingsMode'] ?? null; /**< Language settings mode */

  /// Fetch Configuration Settings
  const fetchConfigurationSettings = async () => {
    const result: IAPIEntityResponse<ISetting> = await asyncErrorHandler(
      getConfigurationSettings()
    );
    const configurationSettings: ISetting = result.data;

    // If there are settings to configure, go to the configuration page to configure the application.
    if (configurationSettings && Object.keys(configurationSettings).length > 0) {
      history.push({
        pathname: SharedCommomRouteName.configurationSettingsRoute,
        state: configurationSettings
      });
    } else {
      // Otherwise, go to the dashboard page.
      history.push({ pathname: SharedMainRouteName.adminRoute });
    }
  };

  useEffect(() => {
    const bypassIOSBackCacheIssue = () => {
      window.onpageshow = (event: any) => {
        if (event.persisted) {
          window.location.reload();
        }
      };
    };

    bypassIOSBackCacheIssue();
  }, []);

  /// Logout License Application.
  const logOutAction = () => {
    const parameters: URLSearchParams = new URLSearchParams({
      logout: 'true',
      themeMode: LocalStorage.getThemeMode(),
      languageSettingsMode: LocalStorage.getLanguageSettingsMode()
    }); /**< Query parameters */
    window.location.href = `${SharedCommomRouteName.validateRoute}?${parameters.toString()}`;
  };

  /// Logout all applications
  const logoutAll = async () => {
    localStorage.setItem(
      'license_applicationURL',
      window.location.origin + window.location.pathname
    );
    const licenseApplicationURL: string =
      localStorage.getItem('license_applicationURL'); /**< License Application URL. */

    if (userApplications.length) {
      localStorage.setItem('userApplications', JSON.stringify(userApplications));
    }
    const applications: IApplications[] = JSON.parse(
      localStorage.getItem('userApplications')
    ); /**< User Applications. */

    const payload: ILogoutAllApplications = {
      applications: applications,
      loggedOutApplicationUUID: loggedOutapplicationUUID,
      logoutAction: logOutAction,
      redirectApplicationURL: licenseApplicationURL
    };
    await asyncErrorHandler(logoutAllApplications(payload));
  };

  useEffect(() => {
    // We should update the received URL token.
    LocalStorage.setTokenFromURL();

    if (themeMode) {
      changeUserTheme(themeMode);
      LocalStorage.setThemeMode(themeMode);

      //Fire a storage event to update theme mode
      window.dispatchEvent(new Event('storage'));
    }

    if (languageSettingsMode) {
      changeUserLanguage(languageSettingsMode, i18n);
      LocalStorage.setLanguageSettingsMode(languageSettingsMode);

      //Fire a storage event to update language settings mode
      window.dispatchEvent(new Event('storage'));
    }

    const verifyUser = async () => {
      const finalThemeMode: string = LocalStorage.getThemeMode() ?? ThemeMode.lightMode;
      const finalLanguageSettingsMode: string =
        LocalStorage.getLanguageSettingsMode() ?? LanguageSettingsMode.english;

      const settings: IAPIEntityResponse<IGetSystemSettingsResponse> = await asyncErrorHandler(
        readSafe()
      );
      const application: IAPIEntityResponse<IApplications> = await asyncErrorHandler(
        readApplicationDetails()
      );
      if (
        settings &&
        settings.data &&
        Object.keys(settings.data).length > 0 &&
        application &&
        application.data &&
        Object.keys(application.data).length > 0
      ) {
        const adminRoleKey: string = settings.data.adminRoleKey;
        const settingsApplicationUUID: string = settings.data.applicationUUID;
        const applicationUUID: string = application.data.applicationUUID;
        const userAuthenticationVerificationURL: string =
          settings.data.userAuthenticationVerificationURL;
        const commonParameters: URLSearchParams = new URLSearchParams({
          app: applicationUUID,
          themeMode: finalThemeMode,
          languageSettingsMode: finalLanguageSettingsMode
        }); /**< Commong query parameters */

        const invalidTokenAuthVerificationURLParameters: URLSearchParams = new URLSearchParams({
          ...Object.fromEntries(commonParameters),
          autoLogoutReason: '403'
        });
        if (!isStringEmptyOrNullOrUndefined(redirectURL)) {
          invalidTokenAuthVerificationURLParameters.append('redirect_url', redirectURL);
        }
        const invalidTokenAuthVerificationURL: string = `${userAuthenticationVerificationURL}?${invalidTokenAuthVerificationURLParameters.toString()}`;

        if (token) {
          if (isTokenValid(token)) {
            const decodedToken: IUserInformationToken = new UserAuthenticationToken(
              jwtDecode(token),
              true
            ).getUserAuthenticationToken();
            //check if user has the application
            const userHasApplication: boolean = hasApplication(
              decodedToken.applications,
              applicationUUID
            );
            if (userHasApplication) {
              //sign user into the application
              dispatch(
                signUserIntoApplicationAction({
                  token,
                  adminRoleUUID: adminRoleKey,
                  applicationUUID: settingsApplicationUUID
                })
              );
              // check if user is an admin
              const isAdmin: boolean = checkIsAdmin(decodedToken.roles, adminRoleKey) || false;
              if (redirectURL && redirectURL !== undefined && redirectURL !== 'undefined') {
                window.location.href = redirectURL;
              } else {
                isAdmin
                  ? fetchConfigurationSettings()
                  : (window.location.href = RouteName.clientHomeRoute);
              }
            } else {
              const parameters: URLSearchParams = new URLSearchParams({
                logout: 'true',
                themeMode: finalThemeMode,
                languageSettingsMode: finalLanguageSettingsMode
              }); /**< Query parameters */
              window.location.href = `${userAuthenticationVerificationURL}?${parameters.toString()}`;
            }
          } else {
            window.location.href = invalidTokenAuthVerificationURL;
          }
        } else {
          const logoutParameters: URLSearchParams = new URLSearchParams({
            ...Object.fromEntries(commonParameters),
            logout: 'true'
          }); /**< Logout query parameters */
          if (autoLogoutReason) {
            const autoLogoutParameters: URLSearchParams = new URLSearchParams({
              ...Object.fromEntries(logoutParameters),
              autoLogoutReason: autoLogoutReason
            }); /**< Autologout query parameters */
            window.location.href = `${userAuthenticationVerificationURL}?${autoLogoutParameters.toString()}`;
          } else if (isLogout) {
            if (!isStringEmptyOrNullOrUndefined(redirectURL)) {
              const parametersWithRedirectURL: URLSearchParams = new URLSearchParams({
                ...Object.fromEntries(logoutParameters),
                redirect_url: redirectURL
              });
              window.location.href = `${userAuthenticationVerificationURL}?${parametersWithRedirectURL.toString()}`;
            } else {
              window.location.href = `${userAuthenticationVerificationURL}?${logoutParameters.toString()}`;
            }
          } else if (isLogoutAll) {
            customLocalStorageClearUtil();
            const logoutAllParameters: URLSearchParams = new URLSearchParams({
              ...Object.fromEntries(commonParameters),
              logoutAll: 'true'
            }); /**< LogoutAll query parameters */
            if (
              redirectApplicationURL &&
              redirectApplicationURL !== undefined &&
              redirectApplicationURL !== 'undefined'
            ) {
              window.location.href = `${redirectApplicationURL}?${logoutAllParameters.toString()}`;
            } else {
              window.location.href = `${userAuthenticationVerificationURL}?${logoutAllParameters.toString()}`;
            }
          } else {
            window.location.href = `${userAuthenticationVerificationURL}?${commonParameters.toString()}`;
          }
        }
      }
    };

    if ((isLogoutAll && userApplications.length) || (isLogoutAll && loggedOutapplicationUUID)) {
      logoutAll(); /**< Logout all application */
    } else {
      verifyUser();
    }
  }, [readSafe, isTokenValid, signUserIntoApplicationAction]);

  return <></>;
};

export default withErrorBoundary(AuthenticationVerificationPage, createLogApi);

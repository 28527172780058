/*
 * LicenseTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file LicenseTable.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { Column } from 'primereact/column';
import Row from 'react-bootstrap/Row';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import LicenseForm from './LicenseForm';
import {
  createLicense,
  deleteLicense,
  getAllLicenses,
  updateLicense
} from '../../../Store/Licenses';
import { useTranslation } from 'react-i18next';
import { getAllEntitlementsWithoutPagination } from '../../../Store/Entitlements';
import {
  createClient,
  // getAllClientsWithoutPagination,
  IClientFormValues
} from '../../../Store/Clients';
import LicenseTableExpansion from './LicenseTableExpansion';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { getSafeSettings } from '../../../Store/Settings';
import UserDetailsPopup, { PopupMode } from '@abstract/abstractwebcommon-client/UserDetailsPopup';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  ILicense,
  LicenseType
} from '@abstract/abstractwebcommon-shared/interfaces/license/license';
import {
  IAuthStateSelector,
  IClientStateSelector,
  IEntitlementStateSelector,
  ILicenseStateSelector,
  ISettingsStateSelector,
  IStateSelectors
} from '../../../Interfaces/Selectors';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import ClientForm from '../Client/ClientForm';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import ConfirmationDialog, {
  ConfirmationDialogMode
} from '@abstract/abstractwebcommon-client/ConfirmationDialog/ConfirmationDialog';
import { RouteName } from '../../../Utils/routesNames';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { MultiSelect } from 'primereact/multiselect';

import './LicenseTable.css';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';

/**
 * @interface IWarningMessage
 */
interface IWarningMessage {
  id: number /**< License Id. */;
  license: string /**< License Name. */;
  totalLinkedActivations: number /**< Total Linked Activations. */;
  userUUID: string /**< User UUID that owns the license. */;
  firstName: string /**< User first name that owns the license. */;
}

const LicenseTable = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const licenses: ILicenseStateSelector = useSelector((state: IStateSelectors) => state.licenses);
  const [editpage, toggleEditPage] = useState<boolean>(false);
  const [license, setLicense] = useState<any>();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [isDeleteLinkedActivationsWarningVisible, setDeleteLinkedActivationsWarningVisible] =
    useState<boolean>(false);

  const licenseList = licenses.list;
  const entitlements: IEntitlementStateSelector = useSelector(
    (state: IStateSelectors) => state.entitlements
  );
  const clients: IClientStateSelector = useSelector((state: IStateSelectors) => state.clients);
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const templateLicenseUUID = settings?.safeSettings?.templateLicenseUUID;
  const [expandedRows, setExpandedRows] = useState<any>();
  const [isClientFormVisible, setClientFormVisible] =
    useState<boolean>(false); /**< Client form visibility. */
  const [userUUID, setUserUUID] = useState(''); /**< User UUID.*/
  const [selectedLicenses, setSelectedLicenses] =
    useState<ILicense[]>(null); /**< Selected Licenses. */
  const deleteButtonReference: any = useRef(null); /**< Delete Button Reference. */
  const [warningMessage, setWarningMessage] = useState<IWarningMessage[]>(); /**< Warning Message */
  const userData: IUser[] = licenses.userData; /**< User data */
  const [selectedLicensesToDelete, setSelectedLicensesToDelete] =
    useState<ILicense[]>(); /**< Licenses to be deleted. */
  const [isDeleteTriggerFromLicenseForm, setDeleteTriggerFromLicenseForm] =
    useState<boolean>(); /**< Is delete triggered from license form */
  const [selectedLicenseType, setSelectedLicenseType] = useState<LicenseType[]>(
    []
  ); /**< Selected License type. */
  const authState: IAuthStateSelector = useSelector(
    (state: IStateSelectors) => state.auth
  ); /**< Authstate */
  const isAdmin: boolean = authState.isAdmin; /**< Loggedin user is admin or not */

  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'created',
      sortOrder: -1
    },
    filter: {}
  }); /**< Default Payload */

  let activeEntitlements;

  if (!entitlements.entitlementIsFetching && entitlements.entitlements) {
    activeEntitlements = entitlements.entitlements.filter((entitlement: any) => entitlement.live);
  }

  /// Action to trigger license add or edit process
  const handleAddOrEditLicense = async (event: any, isEdit: boolean = false) => {
    if (isEdit) {
      // await asyncErrorHandler(
      //   dispatch(getAllClientsWithoutPagination({ filter: '', clientId: event.userUUID }))
      // );
      setLicense(event);
    } else {
      setLicense(null);
    }
    toggleEditPage(true);
  };

  /// License update actions.
  const handleUpdate = async (licenseData: any) => {
    const createOrUpdate: any = license ? updateLicense : createLicense;
    const onRefresh = () => {
      dispatch(getSafeSettings()); // get default license template
      if (!license) {
        // on create from grid, refresh grid
        const updatedPayload = {
          skip: payload.skip,
          limit: payload.limit,
          sort: {
            sortField: payload.sort.sortField,
            sortOrder: payload.sort.sortOrder
          },
          filter: payload.filter,
          searchTerm: payload.searchTerm
        };
        dispatch(getAllLicenses(updatedPayload));
      }
    };

    await asyncErrorHandler(
      dispatch(
        createOrUpdate({
          license: licenseData,
          refresh: onRefresh
        })
      )
    );
    toggleEditPage(false);
    setUserUUID('');
  };

  /// License sorting handler
  const handleSortUpdate = (e: any) => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: e.sortField,
        sortOrder: e.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getAllLicenses(updatedPayload));
  };

  /// License filter handler
  const handleFilterUpdate = (e: any) => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: e.filters || e.filter
    };
    setPayload(updatedPayload);
    dispatch(getAllLicenses(updatedPayload));
  };

  const onFilter = (event: any): void => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      searchTerm: event,
      filter: payload.filter
    };

    setPayload(updatedPayload);
    dispatch(getAllLicenses(updatedPayload));
  };

  /// License pagination handler
  const handlePageUpdate = (e: any) => {
    const { first, rows } = e;
    const updatedPayload = {
      skip: first,
      limit: rows,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getAllLicenses(updatedPayload));
  };

  /// Fetch all licenses
  useEffect(() => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getAllLicenses(updatedPayload));
    dispatch(getSafeSettings()); // get default license template
  }, [dispatch, payload.sort.sortOrder, payload.sort.sortField]);

  // Handles confirmation acceptance button click
  const onAccept = async () => {
    const message: IWarningMessage[] = [];

    selectedLicensesToDelete.forEach((eachLicense: ILicense) => {
      message.push({
        id: eachLicense.id,
        license: eachLicense.projectName,
        totalLinkedActivations: eachLicense.activations.length,
        userUUID: eachLicense.userUUID,
        firstName: eachLicense.user?.firstName
      });
    }); /**< Warning Message. */
    setWarningMessage(message);

    if (message != null && message.length > 0) {
      setDeleteLinkedActivationsWarningVisible(true);
    } else {
      onWarningAccepted();
    }
    setShowConfirmation(false);
  };

  // Dispatch delete action on warning accepted
  const onWarningAccepted = async () => {
    const licenseUUIDs: string[] = selectedLicensesToDelete.map(
      (eachLicense: ILicense) => eachLicense.licenseUUID
    ); /**< licenseUUIDs */

    dispatch(deleteLicense(licenseUUIDs));
    setShowConfirmation(false);
    setDeleteLinkedActivationsWarningVisible(false);

    if (isDeleteTriggerFromLicenseForm) {
      hideDialog();
    } else {
      setSelectedLicenses(null);
    }
  };

  const onReject = () => {
    setShowConfirmation(false);
    setDeleteLinkedActivationsWarningVisible(false);
  };

  const hideDialog = () => toggleEditPage(false);

  /// Action to trigger client add process.
  const handleAddClient = async () => {
    setClientFormVisible(true);
  };

  /// Action to trigger client submit process.
  const handleClientSubmit = async (values: IClientFormValues) => {
    const response: IAPIEntityResponse<IUser> = await asyncErrorHandler(
      dispatch(createClient(values))
    ); /**< Create client. */

    if (response && response.payload && response.payload.result && response.payload.userUUID) {
      setClientFormVisible(false);
    }
  };

  const ConfirmPopupChildrenContent = (): JSX.Element => {
    return warningMessage && warningMessage.length > 0 ? (
      <Col className="mb-3 mt-3 px-0">
        <table className="w-100">
          <tr>
            <th>{t('admin.page.licenses.delete_with_activation_dialog.license_owner_label')}</th>
            <th>{t('admin.page.licenses.delete_with_activation_dialog.project_label')}</th>
            <th>{t('admin.page.licenses.delete_with_activation_dialog.total_linked')}</th>
          </tr>
          {warningMessage.map((message: IWarningMessage) => (
            <tr key={message.id}>
              <td>{message.firstName}</td>
              <td>{message.license}</td>
              <td>{message.totalLinkedActivations}</td>
            </tr>
          ))}
        </table>
      </Col>
    ) : (
      <></>
    );
  };

  const getConfirmPopup = () => {
    return (
      <>
        {!licenses.licenseIsChanging && (
          <ConfirmationPopup
            target={confirmPopupTarget}
            isShow={showConfirmation}
            title={t('/confirm_messages.delete_records')}
            onAccept={onAccept}
            onReject={onReject}
            acceptBtnClass="danger"
            rejectBtnClass="secondary"
            rejectLabel={t('/confirm_messages.no')}
            acceptLabel={t('/confirm_messages.yes')}
            acceptBtnIcon="bi bi-check2-circle"
            rejectBtnIcon="bi bi-x-circle"
            popupPosition="bottom"
          />
        )}

        <ConfirmationDialog
          dialogTitle={t('admin.page.licenses.delete_with_activation_dialog.delete_title')}
          dialogText={t('admin.page.licenses.delete_with_activation_dialog.delete_header')}
          isShowConfirmationModal={isDeleteLinkedActivationsWarningVisible}
          onReject={() => setDeleteLinkedActivationsWarningVisible(false)}
          onAccept={onWarningAccepted}
          cancelButtonText={t(
            'admin.page.licenses.delete_with_activation_dialog.reject_button_label'
          )}
          confirmButtonText={t(
            'admin.page.licenses.delete_with_activation_dialog.accept_button_label'
          )}
          modalMode={ConfirmationDialogMode.Delete}>
          <ConfirmPopupChildrenContent />
        </ConfirmationDialog>
      </>
    );
  };

  // Handler for view as client
  const onClientViewHandler = (rowData: ILicense) => {
    window.open(`${RouteName.adminClientHomeRoute}/${rowData.userUUID}`, '_blank');
  };

  /**
   * License type change handler
   * @param event
   */
  const onLicenseTypeChange = (event: any): void => {
    const licenseTypes: LicenseType[] = event.value; /**< Selected License types */
    setSelectedLicenseType(licenseTypes);

    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      filter: {
        ...updatedPayload.filter,
        licenseType: {
          value: licenseTypes
        }
      }
    });
    setPayload(updatedPayload);

    dispatch(getAllLicenses(updatedPayload));
  };

  const header = (
    <div className="d-flex justify-content-between align-items-center row-direction wrap-header">
      <div className="headerTableContainer margin-bottom-on-wrap">
        {isAdmin ? (
          <>
            <ActionButton
              variant="danger"
              onClick={(event: any) => deleteButtonHandler(event, false)}
              isDisabled={!(selectedLicenses?.length > 0)}
              buttonReference={deleteButtonReference}
            />
            {/* To delete Licenses. */}
            {isAdmin ? (
              <ActionButton name={t('admin.menu.addClient')} onClick={handleAddClient} />
            ) : (
              <></>
            )}
            {/* To Add Client. */}
            <ActionButton
              className="d-none d-sm-flex align-items-center mr-2"
              name={t('admin.menu.addLicense')}
              onClick={handleAddOrEditLicense}
            />
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="headerTableContainer header-search-filter">
        {isAdmin ? (
          <ActionButton
            className="d-flex d-sm-none align-items-center mr-2"
            name={t('admin.menu.addLicense')}
            onClick={handleAddOrEditLicense}
          />
        ) : (
          <></>
        )}{' '}
        {/* To Add License. */}
        <SearchBar onSearchTermChanged={(data: string) => onFilter(data)} />
        {isAdmin ? (
          <MultiSelect
            name="licenseTypeFilter"
            inputId="licenseTypeFilter"
            onChange={onLicenseTypeChange}
            value={selectedLicenseType}
            options={[{ value: LicenseType.Sublicense, label: 'Sublicensed' }]}
            placeholder={t('admin.page.licenses.licenseType')}
            className="p-column-filter"
            filter
            maxSelectedLabels={1}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );

  /// show delete popup
  const deleteButtonHandler = (event: any, isLicenseForm: boolean) => {
    if (isLicenseForm) {
      setSelectedLicensesToDelete([license]);
    } else {
      setSelectedLicensesToDelete(selectedLicenses);
    }
    setDeleteTriggerFromLicenseForm(isLicenseForm);
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows([event.data]);
    }
  };

  /// Handles selection change event
  const onSelectionChange = (event: any) => {
    const rows: ILicense[] = event.value;
    if (Array.isArray(rows)) {
      const selectedRows: ILicense[] = rows.map((row: ILicense) => {
        return row;
      });
      setSelectedLicenses(selectedRows);
    }
  };

  // Defines if the license edit button is disabled.
  // We're allowing client users to only edit licenses where their are the parent license owner.
  const isLicenseEditButtonDisabled = (licenseDetails: ILicense) => {
    return !isAdmin && !licenseDetails.isParentLicense;
  };

  useEffect(() => {
    dispatch(getAllEntitlementsWithoutPagination());
  }, []);

  return (
    <>
      <Row>
        <Col xs={12}>
          <BaseDatatable
            value={licenseList?.records}
            parentClass="licenseDataTable" /**< ClassName for div Component.*/
            header={header}
            filters={payload.filter}
            onFilter={handleFilterUpdate}
            sortField={payload.sort.sortField}
            onSort={handleSortUpdate}
            sortOrder={payload.sort.sortOrder}
            first={payload.skip}
            rows={payload.limit}
            onPage={handlePageUpdate}
            // NOTE: We should include the redux state 'entitlements?.entitlementsIsFetching' here since we display the entitlements in the License Form.
            // Users would be able to click to edit a license even if the 'get all' endpoints have not finished loading.
            isLoading={!licenseList?.records || entitlements?.entitlementsIsFetching}
            totalRecords={licenseList?.totalRecords}
            expandedRows={expandedRows}
            emptyMessage={t('I18N.admin.license.empty_msg')}
            onRowExpand={expandRow}
            onRowCollapse={() => setExpandedRows([])}
            rowExpansionTemplate={(event: any) => (
              <LicenseTableExpansion
                licenseUUID={event.licenseUUID}
                clientList={userData}
                clientListIsFetching={licenses.listIsFetching}
                userUUID={event.user.userUUID}
                rowData={event}
              />
            )}
            selection={selectedLicenses}
            onSelectionChange={(event: any) => onSelectionChange(event)}>
            <Column
              expander
              className={isAdmin ? 'p-0 col-width-3' : 'invisible d-sm-none'}
              headerClassName={isAdmin ? 'p-0 col-width-3' : 'invisible d-sm-none'}
            />
            <Column selectionMode="multiple" className={isAdmin ? 'col-width-3' : 'd-none'} />
            {isAdmin && (
              <Column
                field="userUUID"
                header={t('admin.page.licenses.form.licenseOwner')}
                className="col-width-15"
                body={(rows: any) => {
                  return (
                    <DatatableColumn
                      title={t('admin.page.clients.form.username')}
                      data={
                        <UserDetailsPopup
                          users={rows?.user != null ? [rows?.user] : []}
                          userUUID={rows.user.userUUID}
                          key={rows.id}
                          mode={PopupMode.Complete}
                        />
                      }
                    />
                  );
                }}
              />
            )}
            <Column
              field="projectName"
              header={t('admin.page.licenses.form.licenseProjectName')}
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.licenses.form.licenseProjectName')}
                  data={rows.projectName}
                  isEllipsisVisible={false}
                />
              )}
              // NOTE: We shouldn't apply any breakpoint while displaying this field in the client view.
              className={`${isAdmin ? 'd-table-cell d-sm-none d-md-table-cell col-width-15' : ''}`}
              sortable
            />
            <Column
              field="name"
              header={t('admin.page.licenses.form.licenseEntitlement')}
              // NOTE: We shouldn't apply any breakpoint while displaying this field in the client view.
              className={`${isAdmin ? 'd-table-cell d-sm-none d-xl-table-cell' : 'col-width-20'}`}
              headerClassName={`${
                isAdmin ? 'd-table-cell d-sm-none d-xl-table-cell' : 'col-width-20'
              }`}
              body={(rows: any) => {
                const details = rows.entitlements?.map(
                  (entitlement: IEntitlement, index: number) => {
                    return (
                      <span key={index + 1}>
                        <span className="badge badge-secondary mr-2">
                          {isAdmin ? entitlement.name : entitlement.displayName}
                        </span>
                      </span>
                    );
                  }
                );
                return (
                  <DatatableColumn
                    title={t('admin.page.licenses.form.licenseEntitlement')}
                    data={details}
                  />
                );
              }}
            />
            <Column
              sortable
              field="licenseMaxCount"
              className="d-table-cell d-sm-none d-xxl-table-cell col-width-7"
              headerClassName="d-table-cell d-sm-none d-xxl-table-cell col-width-7"
              header={t('admin.page.licenses.form.licenseMaxCount')}
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.licenses.form.licenseMaxCount')}
                  data={rows.licenseMaxCount}
                />
              )}
            />
            {/* We should display the "Available" column only for the client side */}
            {!isAdmin && (
              <Column
                className="d-table-cell d-sm-none d-sm-table-cell col-width-7"
                headerClassName="d-table-cell d-sm-none d-sm-table-cell col-width-7"
                header={t('admin.page.licenses.form.availableSeats')}
                body={(rows: any) => (
                  <DatatableColumn
                    title={t('admin.page.licenses.form.availableSeats')}
                    data={rows.remainingSeats}
                  />
                )}
              />
            )}
            <Column
              sortable
              field="active"
              className="d-table-cell d-sm-none d-xl-table-cell col-width-5"
              headerClassName="d-table-cell d-sm-none d-xl-table-cell col-width-5"
              header={t('admin.page.licenses.form.active')}
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.licenses.form.active')}
                  data={
                    <ShowCheckOrUncheckIcon value={new Date(rows.licenseEndDate) > new Date()} />
                  }
                />
              )}
            />
            {/* We should display the "Paused" column only for the client side */}
            {!isAdmin && (
              <Column
                sortable
                field="isPaused"
                className="d-table-cell d-sm-none d-md-table-cell col-width-5"
                headerClassName="d-table-cell d-sm-none d-md-table-cell col-width-5"
                header={t('admin.page.licenses.form.isPaused')}
                body={(rows: any) => (
                  <DatatableColumn
                    title={t('admin.page.licenses.form.isPaused')}
                    data={<ShowCheckOrUncheckIcon value={rows.isPaused} />}
                  />
                )}
              />
            )}
            {/* We should display the "Sublicensed" column only for the client side */}
            {!isAdmin && (
              <Column
                className="d-table-cell d-sm-none d-xl-table-cell col-width-7"
                headerClassName="d-table-cell d-sm-none d-xl-table-cell col-width-7"
                header={t('admin.page.licenses.form.isSublicense')}
                body={(rows: any) => (
                  <DatatableColumn
                    title={t('admin.page.licenses.form.isSublicense')}
                    data={
                      <ShowCheckOrUncheckIcon value={rows.isParentLicense || rows.isSublicense} />
                    }
                  />
                )}
              />
            )}
            <Column
              field="licenseStartDate"
              header={t('admin.page.licenses.form.startDate')}
              sortable
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.licenses.form.startDate')}
                  data={formatDate(rows.licenseStartDate)}
                />
              )}
              className="d-table-cell d-sm-none d-3xl-table-cell col-width-7"
              headerClassName="d-table-cell d-sm-none d-3xl-table-cell col-width-7"
            />
            <Column
              field="licenseEndDate"
              header={t('admin.page.licenses.form.endDate')}
              sortable
              className="d-table-cell d-sm-none d-3xl-table-cell col-width-7"
              headerClassName="d-table-cell d-sm-none d-3xl-table-cell col-width-7"
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.licenses.form.endDate')}
                  data={formatDate(rows.licenseEndDate)}
                />
              )}
            />
            {/* We should display the "Created" column only for the admin side */}
            {isAdmin && (
              <Column
                field="created"
                header={t('admin.page.licenses.form.created')}
                sortable
                className="d-table-cell d-sm-none license-created-date-column col-width-7"
                headerClassName=" d-table-cell d-sm-none  license-created-date-column col-width-7"
                body={(rows: any) => (
                  <DatatableColumn
                    title={t('admin.page.licenses.form.created')}
                    data={formatDate(rows.created)}
                  />
                )}
              />
            )}
            {/* We should display the "viewAsClient" button column only for the admin side */}
            {isAdmin && (
              <Column
                field="viewAsClient"
                className="viewAsClient-button-head-container"
                headerClassName="viewAsClient-button-head-container"
                body={(rowData: any) => {
                  return (
                    <div className="d-flex justify-content-center viewAsClient-button-container">
                      <Button
                        className="d-none d-sm-block"
                        onClick={() => onClientViewHandler(rowData)}>
                        {t('admin.menu.viewAsClient')}
                      </Button>

                      <Button
                        className="custom-action-column-action-position d-block d-sm-none position-absolute-mr-30"
                        variant="outline"
                        onClick={() => onClientViewHandler(rowData)}>
                        <i className="bi bi-binoculars editIcon fa-lg text-primary"></i>
                      </Button>
                    </div>
                  );
                }}
              />
            )}
            <Column
              field="edit"
              body={(rowData: any) => {
                return (
                  <Button
                    className="custom-action-column-action-position"
                    variant="outline"
                    onClick={() => handleAddOrEditLicense(rowData, true)}
                    disabled={isLicenseEditButtonDisabled(rowData)}>
                    <i
                      className={`bi bi-pencil-square editIcon fa-lg ${
                        isLicenseEditButtonDisabled(rowData) ? 'custom-disabled-icon' : ''
                      }`}></i>
                  </Button>
                );
              }}
              className="col-width-3 p-0 absolute-position-responsive-screen"
            />
          </BaseDatatable>
        </Col>
      </Row>

      {/* Client Form Dialog. */}
      <Col sm={12}>
        <DialogWrapper
          isDialogVisible={isClientFormVisible}
          headerTitle={t('admin.page.clients.form.header')}
          onHide={() => setClientFormVisible(false)}>
          <ClientForm handleSubmit={handleClientSubmit} isLoading={clients.clientIsChanging} />
        </DialogWrapper>
      </Col>

      {/* License Form Dialog. */}
      <Col sm={12}>
        <DialogWrapper
          isDialogVisible={editpage}
          headerTitle={
            license
              ? t('admin.page.licenses.form.header.edit_licenses_dialog')
              : t('admin.page.licenses.form.header.add_licenses_dialog')
          }
          onHide={() => {
            toggleEditPage(false);
            setLicense(null);
          }}>
          <LicenseForm
            handleSubmit={handleUpdate}
            isLoading={licenses.licenseIsChanging}
            licenseEntitlements={activeEntitlements}
            license={license}
            clientID={userUUID}
            templateLicenseUUID={templateLicenseUUID}
            deleteButtonHandler={deleteButtonHandler}
          />
        </DialogWrapper>
      </Col>
      {getConfirmPopup()}
    </>
  );
};

export default LicenseTable;

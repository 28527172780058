/**
 * sharedFunctions.ts (InstaLOD GmbH)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file sharedFunctions.ts
 * @author Etienne Daher
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {
  IMappedRole,
  IUserInformationToken,
} from './UserAuthenticationToken';
import { validate as uuidValidate } from 'uuid';
import { IShopSettings } from "../interfaces/ecommerce/settings";
import { IProductTranslation } from "../interfaces/ecommerce/ProductTranslation";
import { IStoreTranslation } from "../interfaces/ecommerce/StoreTranslation";
import { IOption } from "../interfaces/ecommerce/Product";
import { LanguageSettingsMode } from "../interfaces/Language";
import { ISubscription } from "../interfaces/ecommerce/Subscription";

const ALPHA_NUMERIC = "ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789"; // without symbols
const NUMERIC = "123456789";
export const baseDateFormat = "dd-MM-yyyy";

/**
 * Splits file to extension or name
 * @param filename full file name with extension
 * @param isExtension boolean specifying which part
 * @returns extension or name
 */
export const splitExtension = (
  filename: string,
  isExtension: boolean
): string => {
  const output = "";
  if (filename && filename.includes(".")) {
    const splits: string[] = filename.split(".");
    return isExtension ? splits[splits.length - 1] : splits[0];
  }
  return output;
};

/**
 * Generates random string of length 10 with the above PASSWORD_CHARS
 * @returns Generated string of length 10
 */
export const generatePassword = (): string => {
  const charactersLength: number = ALPHA_NUMERIC.length;
  const numbersLength: number = NUMERIC.length;
  let result: string = NUMERIC.charAt(
    Math.floor(Math.random() * numbersLength)
  );
  for (let i = 1; i < 12; i++) {
    if (i !== 0 && i % 3 === 0) {
      result += "-";
    }

    result += ALPHA_NUMERIC.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }

  return result;
};

/// Copy a value to the clipboard
export const copyValueHandler = (
  item: any,
  navigator: any,
  successNotificationTrigger: () => void
) => {
  navigator.clipboard.writeText(item);
  successNotificationTrigger();
};

/**
 * Use the JS INTL built in API to format a date given a specific format and an existing date.
 * @param dateFormat Defines the format of the date to display.
 * @param preFormatedDate The date will be formatted according to the dateFormat.
 * @returns string
 */
const formatDateInternal = (dateFormat: Intl.DateTimeFormatOptions, preFormatedDate: Date): string => {
  const navigatorLanguage: string = navigator.language ?? 'en'

  return new Intl.DateTimeFormat(navigatorLanguage, dateFormat).format(preFormatedDate);
}

/**
 * Interface to use in the formatDate utils function.
 * @interface IFormatDateOptions
 */
interface IFormatDateOptions {
  isTime?: boolean /* Include time to date if true. */;
  isTwentyFour?: boolean /* Twenty four hours format if true. */;
  isSecondsHide?: boolean /* Defines if the seconds should be hidden. */;
  isDateWithoutTimestamp?: boolean /* It should be true if the received date doesn't has a timestamp, and it's only have a date (example: 2024-01-01). We need to do a different formatting for this case. */;
}

/**
 * formatDate
 * @param date Date to display.
 * @param isTime Include time to date if true and default if false.
 * @param isTwentyFour Twenty four hours format if true and default if false.
 * @param isSecondsHide Don't display the date seconds.
 * @returns formatted Date
 */
export const formatDate = (date: Date | string, options: IFormatDateOptions = {}) => {
  const isDateWithoutTimestamp: boolean = options.isDateWithoutTimestamp
  const isSecondsHide: boolean = options.isSecondsHide
  const isTime: boolean = options.isTime
  const isTwentyFour: boolean = options.isTwentyFour
  
  // We need this to convert subscription dates.
  // We're storing the subscription dates as 'dd-MM-yyyy', and we can't convert the date correctly without formating it according.
  const isSubscriptionDate = (date: string): Date => {
    const dateSplit: string[] = date.split("-");

    // Extract the day, month, and year from the dateSplit.
    const day: number = parseInt(dateSplit[0], 10);
    const month: number = parseInt(dateSplit[1], 10) - 1;
    const year: number = parseInt(dateSplit[2], 10);

    const newDate: Date = new Date(year, month, day);

    return newDate;
  }

  const preFormatedDate: Date = isDateWithoutTimestamp ? isSubscriptionDate(date as string) : new Date(date);
  
  if (!date || isNaN(preFormatedDate as any)) {
    return "-";
  }

  if (isSecondsHide) {
    return formatDateInternal({
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }, preFormatedDate);
  }

  if (isTime) {
    if (isTwentyFour) {

      return formatDateInternal({
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }, preFormatedDate);
    }

    return formatDateInternal({
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }, preFormatedDate);
  }

  return formatDateInternal({
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }, preFormatedDate)
};

/**
 * get prime react badge class name
 * @returns class name
 */
export enum BadgeClassName {
  new = 'info',
  inProgress = 'warning',
  done = 'success'
};

/**
 * check if files are too big
 * @param files array of files
 * @param maxFileSize maximum file size allowed in megabytes.
 * @returns boolean
 */
export const checkIfFilesAreTooBig = (files: any[], maxFileSize: number): boolean => {
  let valid: boolean = true;
  if (files) {
    files.map((file) => {
      const size = file.size / 1024 / 1024;
      if (size > maxFileSize) {
        valid = false;
      }
    });
  }
  return valid;
};

/**
+ * Get initials from text.
+ * @param text Test string.
+ * @return return initials
+ */
export const getInitials = (text: string) => {
  return text.match(/(\b\S)?/g).join("").toUpperCase()
}

/**
 * Encode URL query.
 * @param query 
 * @returns encoded query.
 */
export const encodeURLQuery = (query: any) => Object.entries(query)
  .map((value) => value.map(encodeURIComponent).join('='))
  .join('&');

/**
 * Encode url parameters
 * @param path 
 * @param query query object.
 * @returns 
 */
export const buildURL = (path: string, query: any) => `${path}?${encodeURLQuery(query)}`;

enum SortOrder {
  ascending = 1,
  descending = -1
}
/**
* Function to sort alphabetically an array of objects by some specific key.
* @param {String} property Key of the object to sort.
*/
export const dynamicSort = (property: string, order?: SortOrder) => {
  let sortOrder: number = order ?? SortOrder.ascending;

  return (first: any, second: any) => {
    const firstValue: any = first[property];
    const secondValue: any = second[property];
    if (firstValue == secondValue)
      return 0;
    if (firstValue < secondValue) {
      return -1 * sortOrder;
    }
    if (firstValue > secondValue) {
      return 1 * sortOrder;
    }
  }
}

/**
 * Function to capitalize first letter of a string
 * @param string 
 * @returns 
 */
export const capitalizeFirstCharacterOnString = (string: string) => {
  const splitString = string.toLowerCase().split(' ');
  for (let i = 0; i < splitString.length; i++) {
    splitString[i] = splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
  }
  return splitString.join(' ');
}

/**
 * Check if user has role.
 * @param userData 
 * @param roleUUID 
 * @returns 
 */
export const isRolePresent = (userData: IUserInformationToken, roleUUID: string): boolean => {
  const roles: IMappedRole[] = userData.roles || userData.role;
  if (roles && roles.length === 0) return false;

  return roles.find(
    (role: IMappedRole) => role.roleUUID === `${roleUUID}`
  )
    ? true
    : false;
}

/**
 * Convert bytes to either Bytes, KB, MB, GB, or TB
 * @param bytes
 * @param decimals Number of decimal place
 * @returns 
 */
export const bytesToSize = (bytes: number, decimals: number = 2) => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(1024));

  if (!bytes || bytes === 0 || sizeIndex < 0) return '0 B';

  const decimalPlaces = decimals < 0 ? 0 : decimals;

  return parseFloat((bytes / Math.pow(1024, sizeIndex)).toFixed(decimalPlaces)) + ' ' + sizes[sizeIndex];
}

/**
 * Format Date as a string stating the time elapsed.
 * @param date javascript Date
 * @returns string stating the time elapsed
 */
export const timeSince = (date: Date) => {
  const now = new Date();
  const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  const dateWithoutTimezone = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  const seconds: number = Math.floor((utc.valueOf() - dateWithoutTimezone.valueOf()) / 1000);
  
  if (seconds < 60) {
    return 'Just Now';
  }
  let interval = seconds / 604800;
  if (interval > 1) {
    if (Math.floor(interval) > 2) {
      return formatDateInternal({
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }, date)
    }
    return Math.floor(interval) === 1
      ? Math.floor(interval) + ' week ago'
      : Math.floor(interval) + ' weeks ago';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + ' day ago'
      : Math.floor(interval) + ' days ago';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + ' hour ago'
      : Math.floor(interval) + ' hours ago';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + ' minute ago'
      : Math.floor(interval) + ' minutes ago';
  }
  return Math.floor(seconds) + ' seconds ago';
};

/** Remove whitespaces from string
 * @param str 
 */
export const removeWhitespacesFromString = (str: string) => {
  if (!str) return;
  return str.replace(/\s+/g, '');
}

/**
 * Get file extension from file name
 * @param filename
 */
export const getExtension = (filename: string): string => {
  const output = '';
  if (filename && filename.includes('.')) {
    const splits = filename.split('.');
    return splits[splits.length - 1];
  }
  return output;
};

/**
 * Checks if the sum of all file sizes are bigger than the specified maximum.
 * @param files array of files
 * @param maxFileSize maximum file size allowed in bytes.
 * @returns boolean
 */
export const maximumFileSize = (files: File[], maximumFileSize: number) => {
  const totalSize: number = files.reduce(
    (previousValue, currentValue) => previousValue + currentValue.size,
    0
  );
  return totalSize > maximumFileSize;
}

/**
 * Checks if value is not undefined, null or an empty string.
 * @param value
 * @returns boolean
 */
export const isStringEmptyOrNullOrUndefined = (value?: string | null): boolean => {
  if (value === undefined || value === null || value === '') {
    return true;
  }
  return false;
}

/**
 * Remove duplicates from array of objects.
 * @param arrayOfObject array of objects to check
 * @param key key of object to check
 * @returns new object
 */
export const removeDuplicatesOnArrayOfObject = (arrayOfObject: any[], key: string) => {
  const uniqueIds: any[] = [];
  const unique = arrayOfObject.filter(object => {
    const isDuplicate = uniqueIds.includes(object[key]);

    if (!isDuplicate) {
      uniqueIds.push(object[key]);
      return true;
    }
    return false;
  });
  return unique;
}

/* Parses a boolean value from any type that can represent a boolean value.
*
* @param value The object to parse from.
* @return The value of "value", but parsed as a boolean value.
*/
export const parseBoolean = (value: any): boolean => {
  switch (value) {
    case undefined:
    case null:
    case false:
    case 'false':
    case 0:
    case '0':
    case 'off':
    case 'no':
    case 'N':
      return false;
    default: return true;
  }
}

/**
 * Remove duplicates from array of strings.
 * @param arrayOfString array of strings to check
 * @returns unique array
 */
export const removeDuplicatesCaseInsensitive = (arrayOfString: string[]): string[] => {
  return Array.from(new Set(arrayOfString.map((value) => value.toLowerCase())));
}

/**
 * Remove Schema from URL.
 * @param link
 * @returns formattedLink
 */
export const removeSchemaFromURL = (link: string) => {
  const url: string = link.replace(/^(?:https?:\/\/)?(?:www\.)?/i, ''); // Remove the schema from the URL.
  return `//${url}`;
};

/**
 * Check if received UUID is valid. Should return true if valid due library bug.
 * Used with .custom() function of express-validator library.
 * @param uuid
 * @returns boolean | Error
 */
export const isValidUUIDExpressValidator = (uuid: string): boolean | Error =>
  uuidValidate(uuid) === true ? true : (function () { throw new Error('Invalid UUID') })()

/**
 * Generates random letters with length based on user input
 * @param stringLength
 * @returns
 */
export const generateRandomLetters = (stringLength: number): string => {
  let result: string = '';
  const characters: string =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength: number = characters.length;
  for (let i: number = 0; i < stringLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * Get the difference between two dates in months
 * @param date1 start date
 * @param date2 end date
 * @return number of months
 */
export const getDatesDifferenceInMonths = (date1: Date, date2: Date): number => {
  let months: number;
  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months <= 0 ? 0 : months; // The value is truncated to 0 if the difference is negative.
};

/**
 * Compare if two array of strings are the same, return the result as boolean.
 * @param firstArray First array to compare
 * @param secondArray Second array to compare
 * @return boolean
 */
export const isArrayOfStringsTheSame = (firstArray: string[], secondArray: string[]): boolean => {
  if (firstArray.length !== secondArray.length) {
    return false;
  }

  const firstSortedArray = firstArray.slice().sort();
  const secondSortedArray = secondArray.slice().sort();

  for (let i = 0; i < firstSortedArray.length; i++) {
    if (firstSortedArray[i] !== secondSortedArray[i]) {
      return false;
    }
  }

  return true;
};

/** Remove non alpha-numeric characters from string
 * @param str 
 */
export const removeNonAlphaNumericCharactersFromString = (str: string) => {
  if (!str) return;
  return str.replace(/[^a-z0-9]/gi, '');
}

/**
 * Get product options description
 * @param transactionProductObject It is from transaction object. So any type
 * @param settings IShopSettings
 * @param selectedLanguage
 * @param productTranslation
 */
export const getProductOptionsDescription = async (
  transactionProductObject: any,
  shopSettings: IShopSettings,
  selectedLanguage: string,
  productTranslation: IProductTranslation[]
): Promise<IOption[]> => {
  const allOptions: IOption[] = []; /**< Product and global options with description */
  // If the user selected product and global options when purchasing, add the description to the options array from the translation data
  if (
    transactionProductObject.options &&
    transactionProductObject.options.length
  ) {
    // Add the product options description
    if (
      transactionProductObject.product.options &&
      transactionProductObject.product.options.length
    ) {
      if (productTranslation) {
        const selectedLanguageTranslation:
          | IProductTranslation
          | undefined = productTranslation.find(
            (data: IProductTranslation) => data.language === selectedLanguage
          ); /**< Selected language tarnslation data */
        const defaultLanguageTranslation:
          | IProductTranslation
          | undefined = productTranslation.find(
            (data: IProductTranslation) =>
              data.language === LanguageSettingsMode.english
          ); /**< Default as English translation data */
        for (
          let index = 0;
          index < transactionProductObject.product.options.length;
          index++
        ) {
          const productOptions: IOption =
            transactionProductObject.product.options[
            index
            ]; /**< Product options */
          const foundOption:
            | IOption
            | undefined = transactionProductObject.options.find(
              (option: IOption) =>
                option._id?.toString() === productOptions._id?.toString()
            );
          if (foundOption) {
            allOptions.push({
              ...foundOption,
              description: !isStringEmptyOrNullOrUndefined(
                selectedLanguageTranslation?.productOptionsDescription?.[index]
              )
                ? selectedLanguageTranslation?.productOptionsDescription?.[
                index
                ]
                : !isStringEmptyOrNullOrUndefined(
                  defaultLanguageTranslation?.productOptionsDescription?.[
                  index
                  ]
                )
                  ? defaultLanguageTranslation?.productOptionsDescription?.[index]
                  : ''
            });
          }
        }
      }
    }
    // Add the global options description
    if (
      shopSettings?.translationData &&
      shopSettings?.translationData.length &&
      shopSettings?.productOptions &&
      shopSettings?.productOptions.length
    ) {
      const selectedLanguageTranslation: IStoreTranslation = shopSettings.translationData.find(
        (data: IStoreTranslation) => data.language === selectedLanguage
      ); /**< Selected language tarnslation data */
      const defaultLanguageTranslation: IStoreTranslation = shopSettings.translationData.find(
        (data: IStoreTranslation) =>
          data.language === LanguageSettingsMode.english
      ); /**< Default as English translation data */
      for (let index = 0; index < shopSettings.productOptions.length; index++) {
        const globalOptions: IOption = shopSettings.productOptions[index];
        const foundOption:
          | IOption
          | undefined = transactionProductObject.options.find(
            (option: IOption) =>
              option._id?.toString() === globalOptions._id?.toString()
          );
        if (foundOption) {
          allOptions.push({
            ...foundOption,
            description: !isStringEmptyOrNullOrUndefined(
              selectedLanguageTranslation?.productOptionsDescription?.[index]
            )
              ? selectedLanguageTranslation?.productOptionsDescription?.[index]
              : !isStringEmptyOrNullOrUndefined(
                defaultLanguageTranslation?.productOptionsDescription?.[index]
              )
                ? defaultLanguageTranslation?.productOptionsDescription?.[index]
                : ''
          });
        }
      }
    }

    if (allOptions && allOptions.length) {
      // If any of the options description does not exists in translation data, use the option name instead of displaying it blank.
      for (let index = 0; index < allOptions.length; index++) {
        if (isStringEmptyOrNullOrUndefined(allOptions[index].description)) {
          allOptions[index].description = allOptions[index].name;
        }
      }
    }
    // If any of the purchased options does not exists in translation data, use the option from transactions option array.
    for (
      let index = 0;
      index < transactionProductObject.options.length;
      index++
    ) {
      const purchasedOption: IOption =
        transactionProductObject.options[index]; /**< Purchased option */
      const foundOption: IOption | undefined = allOptions.find(
        (option: IOption) =>
          option._id?.toString() === purchasedOption._id?.toString()
      );
      // If not found, add the purchased option with description as option name
      if (!foundOption) {
        allOptions.push({
          ...purchasedOption,
          description: purchasedOption.name
        });
      }
    }
  }
  return allOptions;
};

/**
 * Calculate the remaining billing cycles
 * @param braintreeTransactionID
 * @param transactedSubscription 
 * @param productDuration 
 * @returns duration
 */
export const calculateRemaininBillingCycles = (
  braintreeTransactionID: string,
  transactedSubscription: ISubscription | undefined,
  productDuration: number
): number => {
  if (
    !isStringEmptyOrNullOrUndefined(braintreeTransactionID as string) &&
    transactedSubscription &&
    transactedSubscription.braintreeTransactions &&
    transactedSubscription.braintreeTransactions.length
  ) {
    const transaction = transactedSubscription.braintreeTransactions.find(
      (transaction) =>
        transaction.transactionBraintreeID === braintreeTransactionID
    ); /**< Find braintreeTransaction by braintreeTransactionID */
    const subscriptionStartDate: Date = new Date(
      transactedSubscription.subscriptionStartDate
    );
    const transactionCreatedAt: Date = new Date(transaction.createdAt);
    let months =
      (transactionCreatedAt.getFullYear() -
        subscriptionStartDate.getFullYear()) *
      12;
    months -= subscriptionStartDate.getMonth();
    months += transactionCreatedAt.getMonth();
    const billingCycle: number =
      months + 1; /**< Billing cycle of transaction */
    return billingCycle
      ? productDuration - (billingCycle % productDuration)
      : productDuration;
  }

  return productDuration;
};

/**
 * Process include fields for endpoints where we should retrieve an entity with specific fields in its HTTP response body.
 * @param entityToMap Entity to map and get the fields to return
 * @param includeFields Fields we should map to return along with the entity property
 * @returns Partial<T>[]
 */
export const processIncludeFields = <T extends object>(
  entityToMap: T[],
  includeFields: string | string[]
): Partial<T>[] => {
  if (typeof includeFields === 'string') {
    includeFields = includeFields.split(', ').map((field) => field.trim());
  }

  return entityToMap.map((entity: T) => {
    const filteredEntity: Partial<T> = {};

    (includeFields as (keyof T)[]).forEach((field: keyof T) => {
      if (field in entity) {
        Reflect.set(filteredEntity, field, Reflect.get(entity, field));
      }
    });

    return filteredEntity;
  });
};

/**
 * Compare if two objects have the same data. OBS: For now, this function isn't handling nested objects. We should update it according when needed.
 * @param firstObjectToCompare First object to compare.
 * @param secondObjectToCompare Second object to compare.
 * @param ignoreCaseSensitive Define if case sensitive should be ignored.
 * @returns boolean
 */
export const isObjectsEqual = <T extends Record<string, any>> (firstObjectToCompare: T, secondObjectToCompare: T, ignoreCaseSensitive: boolean = false): boolean => {
  const firstArrayKeys: string[] = Object.keys(firstObjectToCompare);
  const secondArrayKeys: string[] = Object.keys(secondObjectToCompare);
  
  if (firstArrayKeys.length !== secondArrayKeys.length) {
    return false;
  }

  for (let key of firstArrayKeys) {
    const value1 = firstObjectToCompare[key];
    const value2 = secondObjectToCompare[key];

    if (ignoreCaseSensitive && typeof value1 === 'string' && typeof value2 === 'string') {
      if (value1.toLowerCase() !== value2.toLowerCase()) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
}

/**
 * Compare if two arrays have the same data, ignoring its data order.
 * @param firstObjectToCompare First array to compare.
 * @param secondArray Second array to compare.
 * @param ignoreCaseSensitive Define if case sensitive should be ignored.
 * @returns boolean
 */
export const isArraysEqual = <T extends Record<string, any>> (firstArray: T[], secondArray: T[], ignoreCaseSensitive: boolean = false): boolean => {
  if (firstArray?.length !== secondArray?.length) {
    return false;
  }

  return firstArray.every(firstArrayItem => secondArray.some(secondArrayItem => isObjectsEqual(firstArrayItem, secondArrayItem, ignoreCaseSensitive)));
}
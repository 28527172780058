/*
 * LanguageSelector.tsx
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file LanguageSelector.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import React, { ReactElement } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Languages, ILanguageData } from '@abstract/abstractwebcommon-shared/interfaces/Language';
import { changeUserLanguage } from '../utils/LanguageSettingsModeUtils';

import './LanguageSelector.css'

/**
 * @interface ILanguageSelector
 */
interface ILanguageSelector {
  i18nService?: any /**< Translate service */;
  selectedLanguage: string /**< Selected Language */;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>> /**< Set selected language. */;
  isFromSidebar?: boolean /**< If the selector is rendered in the Sidebar component. */
}

const LanguageSelector = (properties: ILanguageSelector): ReactElement => {

  /**
   * Language option template
   */
  const languageOptionTemplate = (option: ILanguageData) => {
    return (
      <div id="language-option" className="d-flex align-items-center">
        <img
          alt={option.label}
          src={option.imageURL}
        />
      </div>
    );
  };

  /**
   * Language option template
   */
  const selectedLanguageTemplate = (option: ILanguageData, props) => {
    if (option) {
      return (
        <img
          alt={option.label}
          src={option.imageURL}
          className="mb-2"
        />
      );
    }
    return <span>{props.placeholder}</span>;
  };

  return (
    <Dropdown
      inputId="languageSelector"
      name="languageSelector"
      value={properties.selectedLanguage}
      optionValue="value"
      optionLabel="label"
      onChange={(event) => {
        properties.setSelectedLanguage(event.value);
        if(properties.i18nService) {
          changeUserLanguage(event.value, properties.i18nService);
        }
      }}
      options={Languages}
      disabled={Languages.length === 0}
      itemTemplate={languageOptionTemplate}
      valueTemplate={selectedLanguageTemplate}
      className={`${properties.isFromSidebar ? 'isFromSidebar' : ''} customLanguageSelectorDropdown secondary-border-radius`}
      panelClassName={`${properties.isFromSidebar ? 'customZIndex': ''} customOptionsOverlayContainer`}
    />
  );
};

export default LanguageSelector;

/*
 * LogPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file LogPage.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import {
  getAllLogs,
  exportLogsAction,
  ILogState,
  getLogState,
  logActions
} from '../../../Store/Log';
import LogTable from '@abstract/abstractwebcommon-client/Logs/LogTable';
import Col from 'react-bootstrap/Col';
import { MultiSelect } from 'primereact/multiselect';
import LogDetails from '@abstract/abstractwebcommon-client/Logs/LogDetails';
import { endOfDay, startOfDay } from 'date-fns';
import {
  capitalizeFirstCharacterOnString,
  formatDate
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { Button } from 'react-bootstrap';
import {
  defaultTableLimit,
  defaultTableOptions
} from '@abstract/abstractwebcommon-client/Constants';
import { Range as IDateRange } from 'react-date-range';
import { ILogStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import { ManualDateRangePicker } from '@abstract/abstractwebcommon-client/DateRangePicker/dateRangePicker';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';

/**
 * Interface for LogsPage properties.
 */
const LogsPage = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const logs: ILogStateSelector = useSelector((state: IStateSelectors) => state.logs);
  const logState: ILogState = useSelector(getLogState);
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'createdAt',
      sortOrder: -1
    },
    searchTerm: ''
  }); /**< Default Payload */

  const [expandedRows, setExpandedRows] = useState<any>({});
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState<string>();

  const categories: string[] = [
    t('I18N.logs.categories.entitlement'),
    t('I18N.logs.categories.fileRecord'),
    t('I18N.logs.categories.license'),
    t('I18N.logs.categories.activations'),
    t('I18N.logs.categories.setting'),
    t('I18N.logs.categories.securityViolation'),
    t('I18N.logs.categories.template'),
    t('I18N.logs.categories.licenseTemplate'),
    t('I18N.logs.categories.client'),
    t('I18N.logs.categories.report'),
    t('I18N.logs.categories.newsletter'),
    t('I18N.logs.categories.migration'),
    t('I18N.logs.categories.cronJob'),
    t('I18N.logs.categories.info'),
    t('I18N.logs.categories.error')
  ];

  const securityLevels: string[] = [
    t('I18N.logs.securityLevels.info'),
    t('I18N.logs.securityLevels.danger')
  ];

  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const [selectedSecurityLevels, setSelectedSecurityLevels] = useState<any[]>([]);
  const defaultDate: IDateRange[] = [
    {
      startDate: startOfDay(new Date(2020, 1, 1)),
      endDate: endOfDay(new Date()),
      key: 'selection'
    }
  ]; /**< Initial date. */
  const node: any = useRef();
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange[]>(defaultDate);

  /**
   *
   * @param date Date Range
   */
  const handleDateChangeFilter = (dateRange: IDateRange[]): void => {
    let updatedFilter: any = {};
    if (dateRange !== undefined && dateRange !== null && dateRange.length > 0) {
      /// If values exist then update the filter object with category key
      updatedFilter = {
        ...payload.filter,
        dateRange: {
          value: {
            startDate: startOfDay(new Date(dateRange[0].startDate)),
            endDate: endOfDay(new Date(dateRange[0].endDate))
          }
        }
      };
    } else {
      /// If not omit category key from object
      updatedFilter = { ...payload.filter, dateRange: null };
    }
    onFilter({ filter: updatedFilter });
  };

  const handleSortUpdate = (event: any): void => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: event.sortField,
        sortOrder: event.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getAllLogs(updatedPayload));
  };

  const handlePageUpdate = (event: any): void => {
    const { first, rows } = event;
    const updatedPayload = {
      skip: first,
      limit: rows,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getAllLogs(updatedPayload));
  };

  /// Triggers on dateRange selection change - Sets the value to the filter
  const onDateRangeChange = (event: any): void => {
    setSelectedDateRange([event.selection]);
    handleDateChangeFilter([event.selection]);
  };

  /// Listens for state changes of logState.isLoading and logError and displays toast message accordingly.
  useEffect(() => {
    if (!logState.isLoading) {
      const updatedPayload = {
        skip: payload.skip,
        limit: payload.limit,
        sort: {
          sortField: payload.sort.sortField,
          sortOrder: payload.sort.sortOrder
        },
        filter: payload.filter,
        searchTerm: payload.searchTerm
      };

      dispatch(getAllLogs(updatedPayload));
    }

    if (logState.logError !== null) {
      showToast({
        severity: 'error',
        summary: t('I18N.error_messages.error'),
        detail: t('I18N.error_messages.export_log_failure')
      });
    }
  }, [dispatch, logState.logError]);

  // Listens for export success messages
  useEffect(() => {
    if (logState.isLogExported) {
      showToast({
        severity: 'success',
        summary: t('I18N.success_messages.success'),
        detail: t('I18N.success_messages.export_logs_success')
      });
    }
    dispatch(logActions.reset());
  }, [logState.isLogExported]);

  const onFilter = (event: any): void => {
    const updatedPayload: ITablePayload = payload;

    if (typeof event !== 'string') {
      updatedPayload.filter = event.filter;
    }

    if (typeof event === 'string') {
      Object.assign(updatedPayload, { searchTerm: event });
    }

    if (event.clear) {
      setSelectedCategories([]);
      setSelectedSecurityLevels([]);
      setSelectedDateRange([
        {
          startDate: startOfDay(new Date(2020, 1, 1)),
          endDate: endOfDay(new Date()),
          key: 'selection'
        }
      ]);
    }

    setPayload(updatedPayload);
    dispatch(getAllLogs(updatedPayload));
  };

  const onExportCSV = () => {
    if (searchTerm) {
      Object.assign(payload, { searchTerm: searchTerm });
    }
    dispatch(exportLogsAction(payload));
  };

  const getLogDetails = (rowData: any): JSX.Element => {
    return <LogDetails details={rowData.details} />;
  };

  /// This specifies the template for row expansion.
  const GetRowExpansionTemplate = ({ rowData }: any) => {
    // Capitalize the first letter of Security level
    let securityLevel: string = rowData['level'];
    securityLevel = securityLevel && capitalizeFirstCharacterOnString(securityLevel);

    return (
      <>
        <tr>
          <th>{t('admin.page.logs.date')}</th>
          <td>{rowData.createdAt ? formatDate(rowData.createdAt, { isTime: true }) : ''}</td>
        </tr>
        <tr>
          <th>{t('admin.page.logs.author')}</th>
          <td>{rowData['author']}</td>
        </tr>
        <tr>
          <th>{t('admin.page.logs.category')}</th>
          <td>{rowData['category']}</td>
        </tr>
        <tr>
          <th>{t('admin.page.logs.securityLevel')}</th>
          <td>{securityLevel}</td>
        </tr>
        <tr>
          <th>{t('admin.page.logs.ip')}</th>
          <td>{rowData['ip']}</td>
        </tr>
        <tr>
          <th>{t('admin.page.logs.activity')}</th>
          <td>{rowData['activity']}</td>
        </tr>
        {rowData.details && (
          <>
            <tr>
              <th colSpan={100}>{t('admin.page.logs.details')}</th>
            </tr>
            <div className="px-3">
              <Row>
                <Col sm="12">
                  <p className="mb-0">{getLogDetails(rowData)}</p>
                </Col>
              </Row>
            </div>
          </>
        )}
      </>
    );
  };

  /// This will render the expansion row template.
  const renderExpansionRows = (rowData: any) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any): void => {
    const selectedIds: any = event.value;
    setSelectedLogs(selectedIds);
  };

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data.id]: true });
    }
  };

  /// Triggers on category multiselect change - Sets the value to the filter
  const onCategoryChange = (event: any): void => {
    setSelectedCategories(event.value);
    let updatedFilter = {};
    if (event.value && event.value.length) {
      /// If values exist then update the filter object with category key
      updatedFilter = { ...payload.filter, category: { value: event.value } };
    } else {
      /// If not omit category key from object
      updatedFilter = { ...payload.filter, category: null };
    }

    onFilter({ filter: updatedFilter });
  };

  /// Triggers on security level multiselect change - Sets the value to the filter
  const onSecurityLevelChange = (event: any): void => {
    setSelectedSecurityLevels(event.value);
    let updatedFilter: any = {};
    if (event.value && event.value.length) {
      /// If values exist then update the filter object with security level key
      updatedFilter = { ...payload.filter, level: { value: event.value } };
    } else {
      /// If not omit security level key from object
      updatedFilter = { ...payload.filter, level: null };
    }

    onFilter({ filter: updatedFilter });
  };

  /// Initialze multiselect for log category
  const categoryFilter: JSX.Element = (
    <MultiSelect
      value={selectedCategories}
      placeholder={t('I18N.logs.category_filter_placeholder')}
      options={categories}
      onChange={onCategoryChange}
      appendTo={document.body}
      className="p-column-filter"
      filter
      panelClassName="logCategory" /**< Style applied to multiselect-items-wrapper. */
    />
  );

  /// Initialze multiselect for log security level
  const securityLevelFilter: JSX.Element = (
    <MultiSelect
      value={selectedSecurityLevels}
      placeholder={t('I18N.logs.security_filter_placeholder')}
      options={securityLevels}
      onChange={onSecurityLevelChange}
      appendTo={document.body}
      className="p-column-filter"
      filter
    />
  );

  const header: JSX.Element = (
    <>
      <div className="d-flex justify-content-between align-items-center mobile-screen-width-search-bar">
        <div className="d-flex headerTableContainer">
          <ManualDateRangePicker
            node={node}
            date={selectedDateRange}
            defaultDate={defaultDate}
            handleDateChange={onDateRangeChange}
          />

          <Button
            className="ml-1 mr-2 p-button-icon-only secondary-border-radius"
            onClick={() => onExportCSV()}
            disabled={logState.isExportingLog}
            variant="primary">
            <i className="bi bi-download bi bi-align-center"></i>
          </Button>
        </div>

        <div className="headerTableContainer header-search-filter">
          <SearchBar
            setSearchTerm={setSearchTerm}
            onSearchTermChanged={(data: string) => onFilter(data)}
          />

          {categoryFilter}
          {securityLevelFilter}
        </div>
      </div>
    </>
  );

  return (
    <LogTable
      data={logs.list?.records}
      header={header}
      rowsPerPage={defaultTableOptions}
      payload={{ limit: payload.limit, skip: payload.skip }}
      totalRecords={logs.list?.totalRecords}
      onPage={handlePageUpdate}
      expandedRows={expandedRows}
      getRowExpansionTemplate={renderExpansionRows}
      setExpandedRows={setExpandedRows}
      expandRow={expandRow}
      multiSortMeta={'multiSortMeta'}
      onSort={handleSortUpdate}
      onFilter={onFilter}
      selectedList={selectedLogs}
      onSelectionChange={onSelectionChange}
      sortMode="single"
      dataKey={'id'}
      isLoading={!logs.list?.records}
      parentClass="responsiveBaseDataTable logDataTable"
      className="logDataTable"
      categoryFilterElement={categoryFilter}
      securityLevelFilterElement={securityLevelFilter}
      logDetails={getLogDetails}
      sortField={payload.sort.sortField}
      sortOrder={payload.sort.sortOrder}
      showExpander={true}
      clients={logs.userData}
    />
  );
};

export default LogsPage;

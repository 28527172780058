/*
 * LoginForm.tsx (AbstractUser)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file LoginForm.tsx
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState, ReactElement, useEffect } from "react";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import { ProgressSpinner } from "primereact/progressspinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "primereact/card";
import "./index.css";
import "../services/i18n"; //TODO: init awc and load services from one file only
import ImagePreview from "../ImagePreview";
import Alert from "react-bootstrap/Alert";
import { Link, useLocation } from 'react-router-dom';
import { IUser } from '../../Shared/interfaces/user/user';
import { IPublicApplicationInformation } from "../../Shared/interfaces/user/applications";
import { ThemeMode } from '../../Shared/enum/theme';
import { SharedCommomRouteName } from '../utils/sharedRoutesNames';
import { isStringEmptyOrNullOrUndefined } from "../../Shared/utils/sharedFunctions";
import queryString from 'query-string';
import { maximumCharactersAllowedInPassword, minimumCharactersAllowedInPassword } from "../../Shared/constants";

const LoginClassNames = {
  display: 'custom-display-element',
  hide: 'custom-hide-element'
}

export interface ILoginFormProperties {
  handleSubmit: (data: any) => void;
  isLoading?: boolean;
  showForgotPassword?: boolean;
  forgotPassword?: () => void;
  userData?: IUser; /**< User information. */
  verifyOnDate?: Date; /**< Date a user can send verification. */
  onResendVerification?: (data: IUser) => void; /**< Function called to resend verification. */
  lockedOutError: string; /**< Error message to display when the user is locked out. */
  redirectURL?: string; /**< Redirect URL. */
  application?: IPublicApplicationInformation; /**< Application details. */
  themeMode: string; /**< theme mode to use */
  isDuplicateEmailsEnabled: boolean; /**< Duplicate emails is enabled or not in settings */
}

const LoginForm = ({
  handleSubmit,
  isLoading,
  showForgotPassword,
  forgotPassword,
  userData,
  lockedOutError,
  redirectURL,
  application,
  themeMode,
  isDuplicateEmailsEnabled
}: ILoginFormProperties): ReactElement => {
  const search: string = useLocation().search;
  const parsedString: any = queryString.parse(search);
  const returnURL: string =
    parsedString[
    'returnURL'
    ]; /**< Defines the URL to redirect through the back button when visible. */

  const [isLogoFullLoaded, setLogoFullLoaded] = useState<boolean>(false);
  const [displayLogoClassName, setDisplayLogoClassName] = useState<string>(LoginClassNames.hide);

  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(
          2,
          `${isDuplicateEmailsEnabled ? 
            t("awc:/.login.username_tooltip") 
            : 
            t("awc:/.login.usernameOrEmail_tooltip")} 
            ${t("awc:/validations.min", {
            field: "2",
          })}`
        )
        .max(
          200,
          `${isDuplicateEmailsEnabled ? 
            t("awc:/.login.username_tooltip") 
            : 
            t("awc:/.login.usernameOrEmail_tooltip")} 
            ${t("awc:/validations.max", {
            field: "200",
          })}`
        )
        .required(
          t("awc:/validations.required", {
            field: isDuplicateEmailsEnabled ? 
              t("awc:/.login.username_tooltip") 
              : 
              t("awc:/.login.usernameOrEmail_tooltip"),
          })
        ),
      password: Yup.string()
        .min(
          minimumCharactersAllowedInPassword,
          `${t("awc:/.login.password_tooltip")} ${t("awc:/validations.min", {
            field: minimumCharactersAllowedInPassword,
          })}`
        )
        .max(
          maximumCharactersAllowedInPassword,
          `${t("awc:/.login.password_tooltip")} ${t("awc:/validations.max", {
            field: maximumCharactersAllowedInPassword,
          })}`
        )
        .required(
          t("awc:/validations.required", {
            field: t("awc:/.login.password_tooltip"),
          })
        ),
    }),
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: (data) => handleSubmit(data),
  });

  const [showValidation, setShowValidation] = useState<boolean>(false);

  const onForgotPasswordClick = (event: any) => {
    event.preventDefault();
    if (forgotPassword) {
      forgotPassword();
    }
  };

  const getBackAndLoginButton = () => {
    if (isLoading) {
      return <ProgressSpinner className="d-flex" />;
    }

    return (
      <Col xs={12} className="py-0 d-flex flex-column">
        <Button
          type="submit"
          disabled={
            isLoading ||
            (formik.dirty &&
              Object.keys(formik.errors).length > 0 &&
              showValidation)
            || userData && Object.keys(userData).length > 0
          }
          className="btn-block"
          variant="primary"
        >
          {t("awc:/.login.sign_in")}
        </Button>

        {/* Display a Back button to return to the external app in case the returnURL query parameter is defined */}
        {returnURL ?
          <Button
            className="btn-block mt-2 login-back-button-container"
            variant="primary"
            onClick={() => window.location.href = returnURL}
          >
            {t("awc:/.login.back_button", { applicationName: application.applicationName })}
          </Button>
          : <></>}
      </Col>
    );
  };

  const getForgotPassBtn = () => {
    if (showForgotPassword) {
      return (
        <Col>
          <a
            href="/#"
            onClick={onForgotPasswordClick}
            className="login-form-href"
          >
            {t("awc:/.login.forgot_password")}
          </a>
        </Col>
      );
    }
    return null;
  };

  const noImageContainer = () => {
    return <h2 className="text-center">{application.applicationName ?? ''}</h2>;
  };

  const openNewBrowserTabOnLogoClick = (): void => {
    if(!isStringEmptyOrNullOrUndefined(application.website)) {
      window.open(application.website, '_blank');
    }
  }

  //Note: used to handle when to display the login form to not flick the component when there is a uploaded logo and the logo is loading.
  useEffect(() => {
    if (application.applicationName) {
      if ((application.logoImageURL && isLogoFullLoaded) || !application.logoImageURL) {
        setDisplayLogoClassName(LoginClassNames.display)
      }
    }

  }, [application, isLogoFullLoaded])

  return (
    <Col className={`p-2 loginForm ${displayLogoClassName} login-pages-global-container`}>
      <Card>
        <Row className="text-center justify-content-md-center">
          <Col xs={12}>
            <div 
              onClick={openNewBrowserTabOnLogoClick} 
              className={`${!isStringEmptyOrNullOrUndefined(application.website) ? 'cursor-pointer' : ''}`}
            >
              <ImagePreview
                setLogoFullLoaded={setLogoFullLoaded}
                altText={t("awc:/.login.logo_alt")}
                showDelete={false}
                imageUrl={application?.logoImageURL}
                imgClass={`logoImage ${themeMode === ThemeMode.darkMode ? 'img-filter-grayscale-invert' : ''}`}
                isLogo={true}
                noImageContainer={noImageContainer()}
              />
            </div>
          </Col>
        </Row>

        <h2 className="text-center custom-margin">{t("awc:/.login.header")}</h2>

        <p className="loginDescription text-break custom-margin">{application?.description}</p>
        {lockedOutError && (
          <Alert variant="danger">
            {lockedOutError}
          </Alert>
        )}

        <form onSubmit={formik.handleSubmit}>
          <Row>
            <Col xs={12} className="mb-2">
              <div className="p-inputgroup specialInputs">
                <FormControl
                  id="username"
                  placeholder={isDuplicateEmailsEnabled ? t("awc:/.login.username_tooltip") : t("awc:/.login.usernameOrEmail_tooltip")}
                  type="text"
                  required={true}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onFocus={() => setShowValidation(false)}
                  onBlur={() => setShowValidation(true)}
                  className={
                    formik.touched.username && formik.errors.username
                      ? "p-invalid  text-dark usernameInput"
                      : "text-dark usernameInput"
                  }
                  autoComplete="on"
                />
              </div>
            </Col>

            <Col xs={12} className="pt-2 negateMarginTop">
              <div className="p-inputgroup specialInputs">
                <FormControl
                  id="password"
                  placeholder={t("awc:/.login.password_tooltip")}
                  type="password"
                  required={true}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  minLength={2}
                  onFocus={() => setShowValidation(false)}
                  onBlur={() => setShowValidation(true)}
                  className={
                    formik.touched.password && formik.errors.password
                      ? "p-invalid  text-dark passwordInput"
                      : "text-dark passwordInput"
                  }
                />
              </div>
            </Col>
            {getBackAndLoginButton()}
            <hr />
            {getForgotPassBtn()}
            <Col xs={12} className="d-flex pb-0">
              <span className="py-2 mr-1">{t("awc:/.login.have_no_account")}</span>
              <Link
                to={{ pathname: SharedCommomRouteName.registerRoute, state: { redirectURL: redirectURL, applicationDetails: application } }}
                className="login-form-href py-2"
              >
                {t("awc:/.login.sign_up")}
              </Link>
            </Col>
          </Row>

          {formik.dirty &&
            Object.keys(formik.errors).length > 0 &&
            showValidation ? (
            <Row className="mt-2">
              <Col xs={12} className="pb-0">
                {Object.keys(formik.errors).map((key: string, i) => {
                  return (
                    <Alert variant={"danger"} className="mb-0 mt-2 p-3">
                      <p key={i} className="mb-0">
                        {
                          formik.errors[
                          key as keyof FormikErrors<{
                            username: string;
                            password: string;
                          }>
                          ]
                        }
                      </p>
                    </Alert>
                  );
                })}
              </Col>
            </Row>
          ) : null}
        </form>
      </Card>
    </Col>
  );
};

export default LoginForm;

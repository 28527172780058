/**
* sharedLocalStorage.ts (abstractuser) *

* Copyright © 2023 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2023
* @file sharedLocalStorage.ts
* @author Rafael Rodrigues
* @copyright 2023 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { LanguageSettingsMode } from "../../Shared/interfaces/Language";
import { isStringEmptyOrNullOrUndefined } from '../../Shared/utils/sharedFunctions';

/**
 * Use this file to centralize all local storages keys, values, setter, getter and deletion
 * Add the new local storage value below.
 * @module LocalStorage
 */
export class LocalStorage {
  public static savedStateKey: string = 'saved_state' /**< saved_state local storage key */;

  /**
   * Set saved_state local storage variable value
   * @param {string} newValue New value to set to saved_state local storage
   * @returns {void}
  */
  public static setSavedState(newValue: string): void { localStorage.setItem(this.savedStateKey, newValue) }

  /**
   * Remove saved_state value from local storage variable
   * @returns {void}
  */
  public static removeSavedState(): void { localStorage.removeItem(this.savedStateKey) }

  /**
   * Get saved_state value from local storage variable
   * @returns {string} localStorage.getItem(savedStateKey)
  */
  public static getSavedState(): string { return localStorage.getItem(this.savedStateKey) }

  public static isAdminKey: string = 'isAdmin' /**< isAdmin local storage key */;

  /**
   * Set isAdmin local storage variable value
   * @param {string} newValue New value to set to isAdmin local storage
   * @returns {void}
  */
  public static setAdmin(newValue: string): void { localStorage.setItem(this.isAdminKey, newValue) }

  /**
   * Remove isAdmin value from local storage variable
   * @returns {void}
  */
  public static removeIsAdmin(): void { localStorage.removeItem(this.isAdminKey) }

  /**
   * Get isAdmin value from local storage variable
   * @returns {string} localStorage.getItem(isAdmin)
  */
  public static getIsAdmin(): string { return localStorage.getItem(this.isAdminKey) }

  public static themeModeKey: string = 'theme-mode' /**< theme-mode local storage key */;

  /**
   * Set theme-mode local storage variable value
   * @param {string} newValue New value to set to theme-mode local storage
   * @returns {void}
  */
  public static setThemeMode(newValue: string): void { localStorage.setItem(this.themeModeKey, newValue) }

  /**
   * Remove theme-mode value from local storage variable
   * @returns {void}
  */
  public static removeThemeMode(): void { localStorage.removeItem(this.themeModeKey) }

  /**
   * Get theme-mode value from local storage variable
   * @returns {string} localStorage.getItem(theme-mode)
  */
  public static getThemeMode(): string { return localStorage.getItem(this.themeModeKey) }

  public static xUserUUIDKey: string = 'x-user-id' /**< x-user-id local storage key */;

  /**
   * Set x-user-id local storage variable value
   * @param {string} newValue New value to set to x-user-id local storage
   * @returns {void}
  */
  public static setXUserUUID(newValue: string): void { localStorage.setItem(this.xUserUUIDKey, newValue) }

  /**
   * Remove x-user-id value from local storage variable
   * @returns {void}
  */
  public static removeXUserUUID(): void { localStorage.removeItem(this.xUserUUIDKey) }

  /**
   * Get x-user-id value from local storage variable
   * @returns {string} localStorage.getItem(x-user-id)
  */
  public static getXUserUUID(): string { return localStorage.getItem(this.xUserUUIDKey) }

  public static logoImageUrlKey: string = 'logoImageUrl' /**< logoImageUrl local storage key */;

  /**
   * Set logoImageUrl local storage variable value
   * @param {string} newValue New value to set to logoImageUrl local storage
   * @returns {void}
  */
  public static setLogoImageUrl(newValue: string): void { localStorage.setItem(this.logoImageUrlKey, newValue) }

  /**
   * Remove logoImageUrl value from local storage variable
   * @returns {void}
  */
  public static removeLogoImageUrl(): void { localStorage.removeItem(this.logoImageUrlKey) }

  /**
   * Get logoImageUrl value from local storage variable
   * @returns {string} localStorage.getItem(logoImageUrl)
  */
  public static getLogoImageUrl(): string { return localStorage.getItem(this.logoImageUrlKey) }

  public static xAuthTokenKey: string = 'x-auth-token' /**< x-auth-token local storage key */;

  /**
   * Set x-auth-token local storage variable value
   * @param {string} newValue New value to set to x-auth-token local storage
   * @returns {void}
  */
  public static setXAuthToken(newValue: string): void { localStorage.setItem(this.xAuthTokenKey, newValue) }

  /**
   * Remove x-auth-token value from local storage variable
   * @returns {void}
  */
  public static removeXAuthToken(): void { localStorage.removeItem(this.xAuthTokenKey) }

  /**
   * Get x-auth-token value from local storage variable
   * @returns {string} localStorage.getItem(x-auth-token)
  */
  public static getXAuthToken(): string { return localStorage.getItem(this.xAuthTokenKey) }

  public static logoImageURLKey: string = 'logoImageURL' /**< logoImageURL local storage key */;

  /**
   * Set logoImageURL local storage variable value
   * @param {string} newValue New value to set to logoImageURL local storage
   * @returns {void}
  */
  public static setLogoImageURL(newValue: string): void { localStorage.setItem(this.logoImageURLKey, newValue) }

  /**
   * Remove logoImageURL value from local storage variable
   * @returns {void}
  */
  public static removeLogoImageURL(): void { localStorage.removeItem(this.logoImageURLKey) }

  /**
   * Get logoImageURL value from local storage variable
   * @returns {string} localStorage.getItem(logoImageURL)
  */
  public static getLogoImageURL(): string { return localStorage.getItem(this.logoImageURLKey) }

  public static fullNameKey: string = 'fullName' /**< fullName local storage key */;

  /**
   * Set fullName local storage variable value
   * @param {string} newValue New value to set to fullName local storage
   * @returns {void}
  */
  public static setFullName(newValue: string): void { localStorage.setItem(this.fullNameKey, newValue) }

  /**
   * Remove fullName value from local storage variable
   * @returns {void}
  */
  public static removeFullName(): void { localStorage.removeItem(this.fullNameKey) }

  /**
   * Get fullName value from local storage variable
   * @returns {string} localStorage.getItem(fullName)
  */
  public static getFullName(): string { return localStorage.getItem(this.fullNameKey) }

  public static lastNameKey: string = 'lastName' /**< lastName local storage key */;

  /**
   * Set lastName local storage variable value
   * @param {string} newValue New value to set to lastName local storage
   * @returns {void}
  */
  public static setLastName(newValue: string): void { localStorage.setItem(this.lastNameKey, newValue) }

  /**
   * Remove lastName value from local storage variable
   * @returns {void}
  */
  public static removeLastName(): void { localStorage.removeItem(this.lastNameKey) }

  /**
   * Get lastName value from local storage variable
   * @returns {string} localStorage.getItem(lastName)
  */
  public static getLastName(): string { return localStorage.getItem(this.lastNameKey) }

  public static userNameKey: string = 'username' /**< userName local storage key */;

  /**
   * Set userName local storage variable value
   * @param {string} newValue New value to set to userName local storage
   * @returns {void}
  */
  public static setUserName(newValue: string): void { localStorage.setItem(this.userNameKey, newValue) }

  /**
   * Remove userName value from local storage variable
   * @returns {void}
  */
  public static removeUserName(): void { localStorage.removeItem(this.userNameKey) }

  /**
   * Get userName value from local storage variable
   * @returns {string} localStorage.getItem(userName)
  */
  public static getUserName(): string { return localStorage.getItem(this.userNameKey) }

  public static emailKey: string = 'email' /**< email local storage key */;

  /**
   * Set email local storage variable value
   * @param {string} newValue New value to set to email local storage
   * @returns {void}
  */
  public static setEmail(newValue: string): void { localStorage.setItem(this.emailKey, newValue) }

  /**
   * Remove email value from local storage variable
   * @returns {void}
  */
  public static removeEmail(): void { localStorage.removeItem(this.emailKey) }

  /**
   * Get email value from local storage variable
   * @returns {string} localStorage.getItem(email)
  */
  public static getEmail(): string { return localStorage.getItem(this.emailKey) }

  public static xApplicationUUIDKey: string = 'x-application-id' /**< x-application-id local storage key */;

  /**
   * Set x-application-id local storage variable value
   * @param {string} newValue New value to set to x-application-id local storage
   * @returns {void}
  */
  public static setXApplicationUUID(newValue: string): void { localStorage.setItem(this.xApplicationUUIDKey, newValue) }

  /**
   * Remove x-application-id value from local storage variable
   * @returns {void}
  */
  public static removeXApplicationUUID(): void { localStorage.removeItem(this.xApplicationUUIDKey) }

  /**
   * Get x-application-id value from local storage variable
   * @returns {string} localStorage.getItem(x-application-id)
  */
  public static getXApplicationUUID(): string { return localStorage.getItem(this.xApplicationUUIDKey) }

  public static favouriteIconImageURLKey: string = 'favouriteIconImageURL' /**< favouriteIconImageURL local storage key */;

  public static isRootApplication: string = 'isRootApplication' /**< isRootApplication local storage key */;
  
  /**
   * Set isRootApplication local storage variable value
   * @param {string} newValue New value to set to isRootApplication local storage
   * @returns {void}
  */
  public static setRootApplication(newValue: string): void { localStorage.setItem(this.isRootApplication, newValue) }

  /**
   * Remove isRootApplication value from local storage variable
   * @returns {void}
  */
  public static removeIsRootApplication(): void { localStorage.removeItem(this.isRootApplication) }

  /**
   * Get isRootApplication value from local storage variable
   * @returns {string} localStorage.getItem(isRootApplication)
  */
  public static getIsRootApplication(): string { return localStorage.getItem(this.isRootApplication) }

  /**
   * Set favouriteIconImageURL local storage variable value
   * @param {string} newValue New value to set to favouriteIconImageURL local storage
   * @returns {void}
  */
  public static setFavouriteIconImageURLKey(newValue: string): void { localStorage.setItem(this.favouriteIconImageURLKey, newValue) }

  /**
   * Remove favouriteIconImageURL value from local storage variable
   * @returns {void}
  */
  public static removeFavouriteIconImageURLKey(): void { localStorage.removeItem(this.favouriteIconImageURLKey) }

  /**
   * Get favouriteIconImageURL value from local storage variable
   * @returns {string} localStorage.getItem(favouriteIconImageURL)
  */
  public static getFavouriteIconImageURLKey(): string { return localStorage.getItem(this.favouriteIconImageURLKey) }

  public static firstNameKey: string = 'firstName' /**< firstName local storage key */;

  /**
   * Set firstName local storage variable value
   * @param {string} newValue New value to set to firstName local storage
   * @returns {void}
  */
  public static setFirstName(newValue: string): void { localStorage.setItem(this.firstNameKey, newValue) }

  /**
   * Remove firstName value from local storage variable
   * @returns {void}
  */
  public static removeFirstName(): void { localStorage.removeItem(this.firstNameKey) }

  /**
   * Get firstName value from local storage variable
   * @returns {string} localStorage.getItem(firstName)
  */
  public static getFirstName(): string { return localStorage.getItem(this.firstNameKey) }

  public static roleKey: string = 'role' /**< role local storage key */;

  /**
   * Set role local storage variable value
   * @param {string} newValue New value to set to role local storage
   * @returns {void}
  */
  public static setRole(newValue: string): void { localStorage.setItem(this.roleKey, newValue) }

  /**
   * Remove role value from local storage variable
   * @returns {void}
  */
  public static removeRole(): void { localStorage.removeItem(this.roleKey) }

  /**
   * Get role value from local storage variable
   * @returns {string} localStorage.getItem(role)
  */
  public static getRole(): string { return localStorage.getItem(this.roleKey) }

  public static applicationUUIDKey: string = 'applicationUUID' /**< applicationUUID local storage key */;

  /**
   * Set applicationUUID local storage variable value
   * @param {string} newValue New value to set to applicationUUID local storage
   * @returns {void}
  */
  public static setApplicationUUID(newValue: string): void { localStorage.setItem(this.applicationUUIDKey, newValue) }

  /**
   * Remove applicationUUID value from local storage variable
   * @returns {void}
  */
  public static removeApplicationUUID(): void { localStorage.removeItem(this.applicationUUIDKey) }

  /**
   * Get applicationUUID value from local storage variable
   * @returns {string} localStorage.getItem(applicationUUID)
  */
  public static getApplicationUUID(): string { return localStorage.getItem(this.applicationUUIDKey) }

  public static languageSettingsModeKey: string = 'language-settings-mode'; /**< language settings mode local storage key */;

  /**
   * Set language settings mode local storage variable value
   * @param {string} newValue New value to set to language settings mode local storage
   * @returns {void}
  */
  public static setLanguageSettingsMode(newValue: string): void { localStorage.setItem(this.languageSettingsModeKey, !isStringEmptyOrNullOrUndefined(newValue) ? newValue : LanguageSettingsMode.english) }

  /**
   * Remove language settings mode value from local storage variable
   * @returns {void}
  */
  public static removeLanguageSettingsMode(): void { localStorage.removeItem(this.languageSettingsModeKey) }

  /**
   * Get language settings mode value from local storage variable
   * @returns {string} localStorage.getItem(languageSettingsMode)
  */
  public static getLanguageSettingsMode(): string { return localStorage.getItem(this.languageSettingsModeKey) ?? LanguageSettingsMode.english}

  /**
   * Set the received JWT token from the redirection logic to the 'XAuthToken' local storage key.
   * @returns {string} string
  */
  public static setTokenFromURL(): string | null{
    // NOTE: We should use the JWT token value from the redirection logic URL.
    const urlObject: URL = new URL(window.location.href);
    const urlParams: URLSearchParams = new URLSearchParams(urlObject.search);
    const customJWTTokenURL: string | null = urlParams.get('token');

    if (customJWTTokenURL != null && LocalStorage.getXAuthToken() !== customJWTTokenURL) {
      LocalStorage.setXAuthToken(customJWTTokenURL);
    }

    return customJWTTokenURL ?? null
  }

  /**
   * Use to clear Local Storage. Shouldn't use clear() function to avoid removing necessary storages. 
   * This function doesn't clear the follow storages (please add to list below a storage that must be kept):
   * theme-mode
   * logoImageUrl
   * language-settings-mode
   * applicationUUID > Only used in Ecommerce
   * @returns {void}
  */
  public static removeAllLocalStorage(): void { 
    this.removeSavedState();
    this.removeLastName();
    this.removeXApplicationUUID();
    this.removeFirstName();
    this.removeXUserUUID();
    this.removeIsAdmin();
    this.removeFullName();
    this.removeUserName();
    this.removeXAuthToken();
    this.removeIsRootApplication();
    this.removeRole();
    this.removeEmail();
  }
}
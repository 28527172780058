/*
 * Log.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Log.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  EntityAdapter,
  EntitySelectors,
  EntityState
} from '@reduxjs/toolkit';
import { exportLogsAPI, readLogs } from '../Services/Log';
import { formatTableSortOrder } from '../Utils/Formatter';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import { ILog } from '@abstract/abstractwebcommon-shared/interfaces/license/log';
import {
  IAPIErrorData,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IPaginationSort } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const LOG_FEATURE_KEY = 'logs';
export interface ILogState {
  list: [];
  listIsFetching: boolean;
  log: any;
  logIsFetching: boolean;
  filter: any;
  sort: IPaginationSort<ILog>;
  skip: number;
  limit: number;
  isLoading: boolean /**< is request loading */;
  isLogExported: boolean /**< is requested completed */;
  logError: any /**< log error */;
  isExportingLog: boolean /**< Is exporting log */;
  userData: IUser[] | null /**< UserData */;
}

export interface IFilterLogObject {
  author?: any;
  category?: any;
  activity?: any;
  ip?: any;
  level?: any;
  dateRange?: any;
}

const INITIAL_STATE: ILogState = {
  list: null,
  listIsFetching: false,
  log: null,
  logIsFetching: false,
  filter: {},
  sort: {
    sortField: 'createdAt',
    sortOrder: -1
  },
  skip: 0,
  limit: defaultTableLimit,
  isLoading: false,
  isLogExported: false,
  logError: null,
  isExportingLog: false,
  userData: null
};

export const logAdapter: EntityAdapter<ILogState> = createEntityAdapter();
export const initialLogState: EntityState<ILogState> & ILogState =
  logAdapter.getInitialState(INITIAL_STATE);

const clearErrors = (state: ILogState) => {
  state.isLogExported = false;
  state.isLoading = false;
  state.logError = null;
};

export const logSlice = createSlice({
  name: LOG_FEATURE_KEY,
  initialState: initialLogState,
  reducers: {
    getLogsRequest(state: ILogState) {
      state.listIsFetching = true;
    },
    getLogsSuccess(state: ILogState, action: IReducerAction<Record<string, any>>) {
      state.listIsFetching = false;
      state.list = action.payload.logs;
      state.userData = action.payload.users;
    },
    getLogsFailure(state: ILogState) {
      state.listIsFetching = false;
    },
    reset: (state: ILogState) => clearErrors(state)
  },
  extraReducers: (builder) => {
    builder
      .addCase(exportLogsAction.pending, (state: ILogState) => {
        state.isExportingLog = true;
      })
      .addCase(
        exportLogsAction.fulfilled,
        (state: ILogState, action: IReducerAction<IAPIErrorData>) => {
          if (action.payload && action.payload.error) {
            state.logError = action.payload.error.message || 'error';
          } else {
            state.isLogExported = true;
          }
          state.isExportingLog = false;
        }
      )
      .addCase(
        exportLogsAction.rejected,
        (state: ILogState, action: IReducerAction<IAPIErrorData>) => {
          if (action.error) {
            state.logError = action.error.message || 'error';
          }
          state.isExportingLog = false;
        }
      );
  }
});

/**
 * Used this to not duplicate code.
 * @param payload
 */
const handleSharedVariables = (payload: any) => {
  const sortField: string = payload.sort?.sortField; /**< Sort field. */
  const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
  const searchTerm: string = payload?.searchTerm; /**< Search term to find. */

  const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';
  const formattedFilters: IFilterLogObject = {};
  return {
    sortField,
    searchTerm,
    formattedSortOrder,
    formattedFilters
  };
};

/**
 * Get all logs Action.
 * @param payload
 */
export const getAllLogs = createAsyncThunk('log/all', async (payload: any, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { getLogsSuccess, getLogsFailure, getLogsRequest } = logActions;
  dispatch(getLogsRequest());

  try {
    const sharedVariables = handleSharedVariables(payload);

    if (payload.filter && payload.filter.author) {
      sharedVariables.formattedFilters.author = payload.filter.author.value;
    }

    if (payload.filter && payload.filter.category) {
      sharedVariables.formattedFilters.category = payload.filter.category.value;
    }

    if (payload.filter && payload.filter.activity) {
      sharedVariables.formattedFilters.activity = payload.filter.activity.value;
    }

    if (payload.filter && payload.filter.ip) {
      sharedVariables.formattedFilters.ip = payload.filter.ip.value;
    }

    if (payload.filter && payload.filter.level) {
      sharedVariables.formattedFilters.level = payload.filter.level.value;
    }

    if (payload.filter && payload.filter.dateRange) {
      sharedVariables.formattedFilters.dateRange = payload.filter.dateRange.value;
    }

    const response: PaginatedAPIEntityResponse<ILog> = await asyncErrorHandler(
      readLogs(
        sharedVariables.searchTerm,
        payload.skip,
        payload.limit,
        sharedVariables.sortField,
        sharedVariables.formattedSortOrder,
        sharedVariables.formattedFilters
      )
    );

    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getLogsFailure());
    } else {
      dispatch(getLogsSuccess(response.data));
    }
  } catch (error: any) {
    dispatch(getLogsFailure());
    handleError({ message: error.message });
  }
});

/**
 * Get all logs exported to a .csv file.
 * @param payload
 */
export const exportLogsAction: any = createAsyncThunk('logs/export', async (payload: any) => {
  const sharedVariables = handleSharedVariables(payload);

  if (payload.filter) {
    sharedVariables.formattedFilters.dateRange = payload.filter[0].value;
  }

  const response: void | IAPIErrorData = await asyncErrorHandler(
    exportLogsAPI(
      sharedVariables.searchTerm,
      payload.skip,
      payload.limit,
      sharedVariables.sortField,
      sharedVariables.formattedSortOrder,
      sharedVariables.formattedFilters
    )
  );

  return response;
});

export const logReducer = logSlice.reducer;
export const logActions = logSlice.actions;

const selectors: EntitySelectors<any, EntityState<any>> = logAdapter.getSelectors();
export const selectAll: (state: EntityState<any>) => any[] = selectors.selectAll;
export const selectEntities: (state: EntityState<any>) => Dictionary<any> =
  selectors.selectEntities;
export const getLogState: any = (rootState: any) => rootState[LOG_FEATURE_KEY];
export const selectAllLogs: any = createSelector(getLogState, selectAll);
export const selectLogEntities: any = createSelector(getLogState, selectEntities);

/**
 * Log.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Log.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../config';
import { mapSortingFields, mapFilterFields } from '../Utils/Pagination';
import {
  PaginatedAPIEntityResponse,
  IAPIErrorData,
  IAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { ILog } from '@abstract/abstractwebcommon-shared/interfaces/license/log';
import { IFilterLogObject } from '../Store/Log';
import { downloadFile } from '@abstract/abstractwebcommon-client/utils/downloadFile';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { CreateErrorLog } from '@abstract/abstractwebcommon-shared/utils/CreateErrorLog';

/**
 * Create log
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const createLogApi = (payload: CreateErrorLog): Promise<IAPIEntityResponse<ILog>> => {
  return httpClient.post('/logs/create', payload, null, false, false);
};

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Gets system logs
 * @param skip Skip
 * @param limit Limit
 * @param sort Sort
 * @param sortOrder Sort order
 * @param data Data of filter values
 * @param searchTerm Search term to find
 * @returns Promise
 */
export const readLogs = async (
  searchTerm: string,
  skip = 0,
  limit = 10,
  sort = 'created',
  sortOrder = 'DESC',
  data: any = {}
): Promise<PaginatedAPIEntityResponse<ILog>> => {
  const url = `/logs/all`;
  const mappedFilter: string = mapFilterFields(data, [
    'category',
    'level'
  ]); /** Remap operator of category/level filter as 'IN' */
  const mappedSort: string = mapSortingFields(sort, sortOrder);

  return httpClient.get<ILog>(
    `${url}?skip=${encodeURIComponent(skip)}&limit=${encodeURIComponent(
      limit
    )}&sort=${mappedSort}&filter=${mappedFilter}&searchTerm=${encodeURIComponent(
      searchTerm ? searchTerm : ''
    )}`
  );
};

/**
 * Export logs to a .csv file
 * @param payload Payload to send with the request
 * @returns Promise
 */
export const exportLogsAPI = async (
  searchTerm: string,
  skip = 0,
  limit = 10,
  sort = 'created',
  sortOrder = 'DESC',
  data: IFilterLogObject = {}
): Promise<
  Promise<
    | void
    | IAPIErrorData
    | {
        error: string;
      }
  >
> => {
  const mappedFilter: string = mapFilterFields(data, [
    'category',
    'level'
  ]); /** Remap operator of category/level filter as 'IN' */
  const mappedSort: string = mapSortingFields(sort, sortOrder);

  const url = `/logs/export?skip=${encodeURIComponent(skip)}&limit=${encodeURIComponent(
    limit
  )}&sort=${mappedSort}&filter=${mappedFilter}&searchTerm=${encodeURIComponent(
    searchTerm ? searchTerm : ''
  )}`;

  return downloadFile(url, 'GET', 'logs.csv');
};

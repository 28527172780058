/**
 * license.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file license.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 * @modified Rafael Rodrigues - 2022
 */

import { isStringEmptyOrNullOrUndefined } from "../../utils/sharedFunctions";
import { ICachedUser } from "../UserCache";
import { IActivation } from "./activation";
import { IEntitlement } from "./entitlement";

/**
 * @interface ILicenseFilter
 */
export interface ILicenseFilter {
  projectName?: string; /**< Project name. */
  licenseMaxCount?: number; /**< License max count. */
}

/**
 * @interface ILicense
 */
export interface ILicense extends ILicenseFilter {
  id?: number; /**< Item id. */
  licenseUUID?: string; /**< Item UUID. */
  userUUID?: string; /**< User UUID. */
  userID?: number; /**< User ID bound to the license. */
  user?: ICachedUser; /**< User details. */
  createdBy?: string; /**< Creation by. */
  licenseStartDate?: Date; /**< When license starts. */
  licenseEndDate?: Date; /**< When license ends. */
  entitlements?: IEntitlement[]; /**< License entitlements. */
  activations?: IActivation[]; /**< User License activations. */
  transactionID?: string; /**< Transaction ID. */
  parentLicenseID?: number; /**< Parent license ID. */
  parentLicense?: ILicense | null; /**< Parent license object when dealing with sublicense records. */
  licenseCount?: number /**< Total of licenses. */
  created?: Date; /**< License created date. */
  isPaused?: boolean; /**< Defines if the license is paused or not. */
  hasBillingIssue?: boolean; /**< Determines whether the license is paused due to a failed transaction billing for the product subscription. It is also used to determine when to ping Ecommerce to retrieve the reason for the failed payment. */
  pastDueReason?: string /**< Indicates the reason for the license being paused. The reason is retrieve from pinging the Ecommerce project. */
  sublicenses?: ILicense[] | null /**< Defines the sublicense array bound to the license. */
  userEmail?: string; /**< User email address */
  isParentLicense?: boolean; /**< Defines if the license object is a parent license, which can have sublicenses. */
  isSublicense?: boolean; /**< Defines if the license object is a sublicense license, which have a parent license. */
  remainingSeats?: number; /**< License remaining seats. On regular and sublicenses records, we should do the following calculation: "licenseMaxCount - currentLicenseActivations". While on parent licenses, we should do: "(parentMaxCount - (sublicensesMaxCount + parentLicenseActivationsCount))" */
}

/**
 * @interface ISublicense
 */
export interface ISublicense {
  licenseMaxCount: number; /**< Defines the sublicense max count. */
}

/**
 * @interface ISublicenseCreateDTO
 */
export interface ISublicenseCreateDTO extends ISublicense {
  userEmail?: string; /**< Defines the sublicense owner email. */
  userUUID?: string; /**< Defines the sublicense owner UUID. */
}

/**
 * @interface ISublicenseUpdateDTO
 */
export interface ISublicenseUpdateDTO extends ISublicense {
  userUUID?: string; /**< Defines the sublicense owner UUID. */
  userID?: number; /**< Defines the sublicense owner ID. */
  userEmail?: string; /**< Defines the sublicense owner email to send an invitation. */
  licenseUUID?: string; /**< Defines the sublicense UUID while editing an existing sublicense. */
  entitlements?: IEntitlement[]; /**< License entitlements. */
}

/**
 * @interface ICreateLicense
 */
export interface ICreateLicense {
  newLicense: ILicense; /**< New license */
  isEmailSent: boolean; /**< Email sent or not */
}

/**
 * Interface for the Your License section logic in the client view
 * @interface IMyLicensesSection
 */
export interface IMyLicensesSection {
  licenseUUID: string /**< License UUID. */
  licenseName: string; /**< License name. */
  seats: number; /**< License max count. Total seats available. */
  remainingSeats: string; /**< License remaining seats. */
  licenseStartDate: string; /**< When license starts. */
  licenseEndDate: string; /**< When license ends. */
  entitlementCount: string /**< Number of entitlements linked with the license. */
  isPaused: boolean /**< Defines if the license is paused or not. */
  pastDueReason?: string /**< Indicates the reason for the license being paused. */;
}

/**
 * Specifies the License Type
 */
export enum LicenseType {
  Sublicense = 'Sublicense'
}

/** Determines whether the given license is a parent license or not. The license should have bound the sublicense entitlement.
*/
export function isParentLicense(license: ILicense, sublicenseEntitlementUUID: string): boolean {
  if (isStringEmptyOrNullOrUndefined(sublicenseEntitlementUUID)) {
    throw new Error('sublicenseEntitlementUUID field from Settings page is required.')
  }
  return license.entitlements.some((entitlement: IEntitlement) => entitlement.entitlementUUID === sublicenseEntitlementUUID) && !license.parentLicense;
}

/* 
 * Defines if activations should be removed from the 'license.activations' in case 'deactivationDate' is null, 
 * or in case 'isEntitlement24hourstimeout' is true and 'deactivationDate' date => yesterday date.
*/
export async function manageActivationsWithEntitlement24hourstimeout(licenseDetails: ILicense[]): Promise<ILicense[]> {
  const yesterday: number = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000
  ).getTime(); /**< Yesterday */

  return await Promise.all(
    licenseDetails.map((license: ILicense) => {
      const isEntitlement24hourstimeout: boolean =
        license.entitlements?.some(
          (entitlement) => entitlement.use24HoursTimeout
        );

      license.activations = license.activations?.filter(
        (activation: IActivation) =>
          activation.deactivationDate === null ||
          (isEntitlement24hourstimeout &&
            new Date(activation.deactivationDate).getTime() >=
            yesterday)
      );
      return license;
    })
  )
}

/**
  * Calculates the available seats given a specific PARENT license and its entitlements.
  * (licenseDetails.licenseMaxCount - (totalSublicensesMaxCount + licenseDetails.activations?.length))
  * @param license Defines the parent licese to verify available seats.
  * @returns number
*/
export function calculateParentLicenseAvailableSeats(licenseDetails: ILicense): number {
  const totalSublicensesMaxCount: number = licenseDetails.sublicenses?.reduce((sum: number, sublicense: ILicense) => sum + sublicense.licenseMaxCount, 0) ?? 0
  const remainingSeats: number = (licenseDetails.licenseMaxCount - (totalSublicensesMaxCount + licenseDetails.activations?.length ?? 0))
  return remainingSeats
}

/**
  * Get the parent license available seats. If no seats are available, we are returning 0 as value here.
  * @param license Defines the parent licese to verify available seats.
  * @returns number
*/
export function getParentLicenseAvailableSeatsPositiveValues(licenseDetails: ILicense): number {
  return Math.max(calculateParentLicenseAvailableSeats(licenseDetails) ?? 0, 0)
}

/* 
 * Calculates the available seats given a specific REGULAR or Sublicense record and its entitlements.
 * (licenseDetails.licenseMaxCount - (totalSublicensesMaxCount + licenseDetails.activations?.length))
*/
export function calculateRegularORSublicenseAvailableSeats(licenseDetails: ILicense): number {
  const remainingSeats: number = licenseDetails.licenseMaxCount - licenseDetails.activations?.length ?? 0
  // If the remaining seats is negative, we should return 0.
  return Math.max(remainingSeats, 0)
}

/*
 * LanguageSettingsModeUtils.tsx
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file LanguageSettingsModeUtils.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
  Use to change language.
  @param String language - language state to update
  @param Service i18nService - service to translate
  @return void
*/
export const changeUserLanguage = (language: string, i18nService: any): void => {
  i18nService.changeLanguage(language)
}